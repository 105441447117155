import React from 'react';
import styled from 'styled-components';
import { LoadingSpinner } from '@lib/nvstr-common-ui.es';

const SiteOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.primary.oak};
  z-index: 10;
`;

const SpinnerWrap = styled.div`
  width: 50px;
  margin: 80px auto 0;
`;

const LoadingScreen = () => (
  <SiteOverlay>
    <SpinnerWrap>
      <LoadingSpinner />
    </SpinnerWrap>
  </SiteOverlay>
);

export default LoadingScreen;
