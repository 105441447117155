import { COLOR_THEME_NAMES } from '@lib/nvstr-utils.es';

export const PRODUCT_COMMISSION_FEE = 4.5;

export const PRODUCT_NAMES = {
  Tornado: 'Tornado',
  TheStreet: 'TheStreet',
};

export const PRODUCT_DISPLAY_NAMES = {
  [PRODUCT_NAMES.Tornado]: 'Tornado',
  [PRODUCT_NAMES.TheStreet]: 'TheStreet powered by Tornado',
};

const PRODUCT_PARTNER_IDS = {
  [PRODUCT_NAMES.Tornado]: null,
  [PRODUCT_NAMES.TheStreet]: '1',
};

export const PRODUCT_DEFAULTS = {
  [PRODUCT_NAMES.Tornado]: {
    domain: 'tornado.com',
    supportEmail: 'support@tornado.com',
    colorTheme: COLOR_THEME_NAMES.light,
    lightColorTheme: COLOR_THEME_NAMES.light,
    darkColorTheme: COLOR_THEME_NAMES.dark,
    appStore: {
      ios: 'https://apps.apple.com/us/app/nvstr-invest-trade-discuss/id1481266478?ls=1',
      android: 'https://play.google.com/store/apps/details?id=com.nvstr.app',
    },
  },
  [PRODUCT_NAMES.TheStreet]: {
    domain: 'thestreet.tornado.com',
    colorTheme: COLOR_THEME_NAMES.theStreet,
    lightColorTheme: COLOR_THEME_NAMES.theStreet,
    darkColorTheme: COLOR_THEME_NAMES.dark,
    supportEmail: 'support@thestreet.tornado.com',
    appStore: {
      ios: 'https://apps.apple.com/us/app/thestreet-powered-by-tornado/id1667981377',
      android: 'https://play.google.com/store/apps/details?id=com.thestreet.poweredbytornado.app',
    },
  },
};

export const PRODUCT_FONTS = {
  [PRODUCT_NAMES.Tornado]: {
    normal: 'Bolton, Arial',
    bold: 'Bolton, Arial',
    thin: 'Bolton, Arial',
  },
  [PRODUCT_NAMES.TheStreet]: {
    heading: 'oswald, Roboto, tahoma, sans-serif',
    normal: 'Roboto, Arial, sans-serif',
    bold: 'Roboto, Arial, sans-serif',
    thin: 'Roboto, Arial, sans-serif',
  },
};

export const PRODUCT_NAME = PRODUCT_NAMES.Tornado;
export const PRODUCT_DISPLAY_NAME = PRODUCT_DISPLAY_NAMES[PRODUCT_NAME] || null;
export const PRODUCT_DISPLAY_FULL_NAME = PRODUCT_DISPLAY_NAMES[PRODUCT_NAME];

export const PRODUCT_IOS_APP_STORE_URL = PRODUCT_DEFAULTS[PRODUCT_NAME].appStore.ios;
export const PRODUCT_ANDROID_APP_STORE_URL = PRODUCT_DEFAULTS[PRODUCT_NAME].appStore.android;

export const LIGHT_COLOR_THEME_NAME = PRODUCT_DEFAULTS[PRODUCT_NAME].lightColorTheme;
export const DARK_COLOR_THEME_NAME = PRODUCT_DEFAULTS[PRODUCT_NAME].darkColorTheme;

export const IS_WHITE_LABELED = PRODUCT_NAME !== PRODUCT_NAMES.Tornado;

export const PRODUCT_PARTNER_ID = PRODUCT_PARTNER_IDS[PRODUCT_NAME] || null;

export const FONT = PRODUCT_FONTS[PRODUCT_NAME];

export const DEFAULT_COLOR_THEME = PRODUCT_DEFAULTS[PRODUCT_NAME].colorTheme;

export const areLightDarkThemesEnabled = PRODUCT_NAME === PRODUCT_NAMES.Tornado;

export const OP_CO_NAME = 'Nvstr Financial LLC dba Tornado';

export const EMAIL_DOMAIN = PRODUCT_DEFAULTS[PRODUCT_NAME].domain;
export const DOMAIN = PRODUCT_DEFAULTS[PRODUCT_NAME].domain;
export const SUPPORT_EMAIL = PRODUCT_DEFAULTS[PRODUCT_NAME].supportEmail;
export const TWITTER_HANDLE = 'TornadoOfficial';

export const LOG_AMPLITUDE_EVENTS = false;
export const useAdvancedLogging = false;
export const useStoreLoggingDiff = false;
