import { currentUserActionTypes } from '@constants/actionTypes';

const defaultState = { id: null };

export default function (state = defaultState, action) {
  switch (action.type) {
    case currentUserActionTypes.fetch:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
