import { karmaActionTypes } from '@constants/actionTypes';

const defaultState = {
  karmaTotal: null,
  reachedDailyMax: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case karmaActionTypes.addKarma: {
      const karmaEarned = action.payload;
      if (!isNaN(karmaEarned)) {
        return {
          ...state,
          karmaTotal: state.karmaTotal + karmaEarned,
          reachedDailyMax: false,
        };
      } else {
        return state;
      }
    }

    case karmaActionTypes.updateKarma: {
      const karma = action.payload;
      if (isNaN(karma)) {
        return state;
      }

      return {
        ...state,
        karmaTotal: action.payload,
      };
    }

    case karmaActionTypes.setReachedMaxDailyKarma: {
      return {
        ...state,
        reachedDailyMax: true,
      };
    }

    default:
      return state;
  }
}
