import { parseQueryString } from '@lib/nvstr-utils.es';

export const LOCAL_API_DOMAIN = 'https://dev-api.tornado.com';
export const API_URL = process.env.NEXT_PUBLIC_API_URL || LOCAL_API_DOMAIN;
export const BASEURL = API_URL;

console.log('api:' + BASEURL);

export const extendedFetch = (url, method, body, params_headers) => {
  const username = 'nvstr';
  const password = 'investbettertomorrow';
  const headers = new Headers();

  headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');
  headers.set('Authorization', 'Basic ' + btoa(username + ':' + password));
  params_headers?.forEach((e) => {
    headers.set(e[0], e[1]);
  });

  return fetch(url, {
    method: method,
    credentials: 'include',
    headers,
    body,
  });
};

export const isFastWebappRoute = (path) => {
  return path.slice(0, 3) === '/a/';
};

export const isWebappRoute = (path) => {
  return path.slice(0, 3) === '/app/';
};

export const isBlogRoute = (path) => {
  return path.slice(0, 5) === '/blog';
};

export const getEmailAuthTokenFromUrl = () => {
  const searchParams = window.location.search;
  const searchObj = parseQueryString(searchParams);
  const auth_id = searchObj.auth_id || null;
  const token = searchObj.token || null;
  return {
    authId: auth_id,
    token,
  };
};

export const storeEmailAuthToken = () => {
  const { token, authId } = getEmailAuthTokenFromUrl();
  if (token && authId) {
    window.sessionStorage.setItem('token', token);
    window.sessionStorage.setItem('authId', authId);
    return true;
  }
  return false;
};

export const getEmailAuthTokenFromSessionStorage = () => {
  const token = window.sessionStorage.getItem('token') || null;
  const authId = window.sessionStorage.getItem('authId') || null;
  return {
    token,
    authId,
  };
};

export const getEmailAuthToken = () => {
  const { token, authId } = getEmailAuthTokenFromSessionStorage();
  if (token && authId) {
    return {
      token,
      authId,
    };
  } else {
    const { token, authId } = getEmailAuthTokenFromUrl();
    return {
      token,
      authId,
    };
  }
};

export const getEmailTokenParamsString = () => {
  try {
    const { token, authId } = getEmailAuthToken();
    if (authId && token) {
      return `?auth_id=${authId}&token=${token}`;
    }
    return '';
  } catch (e) {
    console.error(e);
    return '';
  }
};
