import React from 'react';
import LoadingScreen from '@components/UI/LoadingScreen';
import { isSignedIn, storeSessionParams } from '@utils/services';
import { PUBLIC_ROUTES } from '@constants/publicRoutes';
import './../base.css';
import '../styles/globals.css';
import { useRouter } from 'next/router';
import { TrackIteratively } from '@utils/itly';
import itly from '@utils/itly/itly.service';
import { analyticsPageNames } from '@utils/itly/analyticTypes';
import { ThemeContainer } from '../container/utils/ThemeContainer';
import configureStore from '@utils/reducers/configureStore';
import { Provider } from 'react-redux';
import { isBlogRoute, isFastWebappRoute, isWebappRoute } from '@utils/index';
import { initAmplitudeInstance, logAmplitudeEvent } from '@utils/amplitude';
import { TrackingEvent } from '@lib/nvstr-utils.es';

const store = configureStore();

let TIK_TOK_PIXEL_ID = '0';
let STACK_ADAPT_PIXEL_ID = '0';
let NEXT_PUBLIC_BRANCH_KEY = '0';

try {
  TIK_TOK_PIXEL_ID = process.env.NEXT_PUBLIC_TIK_TOK_PIXEL_ID || '0';
  STACK_ADAPT_PIXEL_ID = process.env.NEXT_PUBLIC_STACK_ADAPT_PIXEL_ID || '0';
  NEXT_PUBLIC_BRANCH_KEY = process.env.NEXT_PUBLIC_BRANCH_KEY || '0';
} catch (e) {
  console.error(e);
}

const BASEURL = process.env.NEXT_PUBLIC_API_URL;

const useForwardAuthedUserToApp = (onLeavingSite) => {
  const {
    location: { pathname, href },
  } = window;

  const forwardToApp = () => {
    const params = href.split('?');
    const urlParams = params.length && params[1] ? `?${params[1]}` : '';
    onLeavingSite();
    window.location = `/app${urlParams}`;
  };

  React.useEffect(() => {
    if (!PUBLIC_ROUTES.includes(pathname)) {
      if (!isFastWebappRoute(pathname) && !isBlogRoute(pathname) && !isWebappRoute(pathname)) {
        isSignedIn({ forwardToApp });
      }
    }
  }, []);

  return null;
};

const useTracking = () => {
  const router = useRouter();

  React.useEffect(() => {
    TrackingEvent.addEventSender((name, properties) => logAmplitudeEvent(name, properties));
  }, []);

  React.useEffect(() => {
    if (router.isReady) {
      itly.initialize();
    }
  }, [router.isReady]);

  React.useEffect(() => {
    const screen = analyticsPageNames[router.pathname];
    const properties = {
      location: screen,
      url: window.location.pathname,
      url_query: window.location.search,
    };
    TrackingEvent.create('View Page', properties);
  }, [router]);

  return null;
};

const useStoreReferer = () => {
  const {
    location: { search },
  } = window;

  React.useEffect(() => {
    const handleRefererParams = () => {
      if (search !== '') {
        const url = BASEURL + search;
        storeSessionParams(url);
      }
    };
    handleRefererParams();
  }, []);

  return null;
};

const usePageTrackers = () => {
  React.useEffect(() => {
    initAmplitudeInstance();
  }, []);

  React.useEffect(() => {
    !(function (w, d, t) {
      w.TiktokAnalyticsObject = t;
      var ttq = (w[t] = w[t] || []);
      (ttq.methods = [
        'page',
        'track',
        'identify',
        'instances',
        'debug',
        'on',
        'off',
        'once',
        'ready',
        'alias',
        'group',
        'enableCookie',
        'disableCookie',
      ]),
        (ttq.setAndDefer = function (t, e) {
          t[e] = function () {
            t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
          };
        });
      for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
      (ttq.instance = function (t) {
        for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);
        return e;
      }),
        (ttq.load = function (e, n) {
          var i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
          (ttq._i = ttq._i || {}),
            (ttq._i[e] = []),
            (ttq._i[e]._u = i),
            (ttq._t = ttq._t || {}),
            (ttq._t[e] = +new Date()),
            (ttq._o = ttq._o || {}),
            (ttq._o[e] = n || {});
          n = document.createElement('script');
          (n.type = 'text/javascript'), (n.async = !0), (n.src = i + '?sdkid=' + e + '&lib=' + t);
          e = document.getElementsByTagName('script')[0];
          e.parentNode.insertBefore(n, e);
        });

      ttq.load(TIK_TOK_PIXEL_ID);
      ttq.page();
    })(window, document, 'ttq');

    !(function (s, a, e, v, n, t, z) {
      if (s.saq) return;
      n = s.saq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!s._saq) s._saq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '1.0';
      n.queue = [];
      t = a.createElement(e);
      t.async = !0;
      t.src = v;
      z = a.getElementsByTagName(e)[0];
      z.parentNode.insertBefore(t, z);
    })(window, document, 'script', 'https://tags.srv.stackadapt.com/events.js');
    window.saq('ts', STACK_ADAPT_PIXEL_ID);

    // load Branch
    (function (b, r, a, n, c, h, _, s, d, k) {
      if (!b[n] || !b[n]._q) {
        for (; s < _.length; ) c(h, _[s++]);
        d = r.createElement(a);
        d.async = 1;
        d.src = 'https://cdn.branch.io/branch-latest.min.js';
        k = r.getElementsByTagName(a)[0];
        k.parentNode.insertBefore(d, k);
        b[n] = h;
      }
    })(
      window,
      document,
      'script',
      'branch',
      function (b, r) {
        b[r] = function () {
          b._q.push([r, arguments]);
        };
      },
      { _q: [], _v: 1 },
      'addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking qrCode'.split(
        ' '
      ),
      0
    );
    // init Branch
    window.branch.init(NEXT_PUBLIC_BRANCH_KEY);
  }, []);

  React.useEffect(() => {
    if (window?.fbq && typeof window.fbq === 'function') {
      window?.fbq('track', 'PageView');
    } else {
      console.log('skipping fbq init not found');
    }
    if (window?.ga && typeof window.ga === 'function') {
      window?.ga('send', 'pageview', window.location.pathname);
    }
  }, []);

  return null;
};

const Managers = ({ onRedirect, children }) => {
  useTracking();
  useStoreReferer();
  useForwardAuthedUserToApp(onRedirect);
  usePageTrackers();

  return children;
};

function Main({ Component, pageProps }) {
  const [mounted, setMounted] = React.useState(false); // fix for window being undefined on first render
  const [isLeavingSite, setIsLeavingSite] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  const onRedirect = () => {
    setIsLeavingSite(true);
  };

  if (!mounted) {
    return null;
  }

  return (
    <Provider store={store}>
      <ThemeContainer>
        {isLeavingSite ? (
          <LoadingScreen />
        ) : (
          <Managers onRedirect={onRedirect}>
            <Component {...pageProps} />
          </Managers>
        )}
      </ThemeContainer>
    </Provider>
  );
}

export default Main;
