import { PRODUCT_PARTNER_ID } from '../appConfig';
import { getEmailTokenParamsString } from '@utils/index';

const BASEURL = process.env.NEXT_PUBLIC_API_URL;
export const STRAPI_BASE_URL = process.env.NEXT_PUBLIC_STRAPI_API_URL;
const STRAPI_PUBLIC_TOKEN = process.env.NEXT_PUBLIC_STRAPI_TOKEN;

export async function getFromCMSApi({ dir, query = '', options = {} }) {
  try {
    const url = `${STRAPI_BASE_URL}/api${dir}${query}`;
    console.log({ url });
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${STRAPI_PUBLIC_TOKEN}`,
      },
    });
    if (response?.status === 200) {
      const data = await response.json();
      return data;
    } else {
      return {
        error: true,
      };
    }
  } catch (e) {
    console.error(e);
    return {
      error: true,
    };
  }
}

export async function isSignedIn({ forwardToApp }) {
  try {
    if (!BASEURL || window?.location.hostname === 'localhost') {
      // in local dev mode this is disabled
      console.log('Is signed in check disabled on localhost');
      return false;
    }
    const tokenParams = getEmailTokenParamsString();
    const getCSRF = await fetch(`${BASEURL}/api/v1/util/csrf${tokenParams}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const data = await getCSRF.json();
    const { is_signed_in } = data;

    if (is_signed_in) {
      console.log('is signed in');
      if (window.location.hostname !== 'localhost') {
        forwardToApp && forwardToApp();
      }
      return true;
    }
    return false;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function storeSessionParams(url) {
  try {
    await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  } catch (e) {
    console.log(e);
  }
}

export async function signUpEmail({ email, userSignUpComplete, userSignUpError, landerSlug }) {
  try {
    // -- Get initial CSRF token
    const getCSRF = await fetch(`${BASEURL}/api/v1/util/csrf`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const data = await getCSRF.json();
    const { csrf_token } = data;

    const extraProperties = {};

    if (PRODUCT_PARTNER_ID) {
      extraProperties.white_label_partner_id = PRODUCT_PARTNER_ID;
    }

    const signUpEmail = await fetch(`${BASEURL}/api/v1/lead`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        email,
        lander_slug: landerSlug,
        ...extraProperties,
      }),
    });

    const signUpData = await signUpEmail.json();

    if (signUpData.error) {
      userSignUpError(signUpData.error);
    } else {
      userSignUpComplete();
    }
  } catch (e) {
    console.error(e);
  }
}
