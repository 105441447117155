import { trackingEvents } from '@lib/nvstr-utils.es';
import ItlyTrackWrapper from './ItlyTrackWrapper';

const BUTTON_CLICKED = new ItlyTrackWrapper(trackingEvents.buttonClicked);

const genericTrackIteratively = {
  BUTTON_CLICKED,
};

export default genericTrackIteratively;
