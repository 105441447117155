import { combineReducers } from 'redux';

import currentUser from '@utils/reducers/currentUser';
import learn from '@utils/reducers/learn';
import karma from '@utils/reducers/karma';

const rootReducer = combineReducers({
  currentUser,
  learn,
  karma,
});

export default rootReducer;
