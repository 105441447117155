import React from 'react';
import { useScreenSize } from '@lib/nvstr-common-ui.es';
import { breakpoints, themes, colorPalette } from '@lib/nvstr-utils.es';
import { DARK_COLOR_THEME_NAME, FONT, LIGHT_COLOR_THEME_NAME } from '../../appConfig';
import styled, { ThemeProvider } from 'styled-components';
import useColorTheme from '../../hooks/useColorTheme';

const getWindowWidth = () => {
  try {
    let width = window?.innerWidth || null;
    if (isNaN(width)) {
      width = null;
    }
    return width;
  } catch (e) {
    return null;
  }
};

const StyleWrapper = styled.div`
  min-height: 100vh;

  body {
    background: ${({ theme }) => theme.themeColors.appBackground};
  }

  span,
  div,
  a,
  div,
  p {
    font-family: ${({ theme }) => FONT.normal};
  }

  span,
  div,
  p {
  }

  a {
  }

  .thin {
    font-family: ${({ theme }) => FONT.thin};
  }

  .bold,
  b,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${({ theme }) => FONT.bold};
    font-weight: 600;
  }
`;

export function ThemeContainer({ children }) {
  const [windowWidth, setWindowWidth] = React.useState(getWindowWidth());
  const colorThemeName = useColorTheme(true);
  const colorTheme = useColorTheme();
  const screenSize = useScreenSize();

  React.useEffect(() => {
    const handleResize = () => {
      const newWidth = getWindowWidth();
      if (windowWidth !== newWidth) {
        setWindowWidth(newWidth);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const theme = {
    lightTheme: themes[LIGHT_COLOR_THEME_NAME],
    darkTheme: themes[DARK_COLOR_THEME_NAME],

    colorThemeName,
    themeColors: themes[colorThemeName],
    themes: themes,

    isDarkTheme: colorThemeName === DARK_COLOR_THEME_NAME,
    isMobile: windowWidth < 1000,

    ...colorPalette,
    colorPalette,
    breakpoints,

    colorTheme,
    apiName: colorThemeName,
    screenSize,
  };
  return (
    <ThemeProvider theme={theme}>
      <StyleWrapper theme={theme}>{children}</StyleWrapper>
    </ThemeProvider>
  );
}
