import { trackingEvents } from '@lib/nvstr-utils.es';
import ItlyTrackWrapper from './ItlyTrackWrapper';

const PAGE_VIEWED = new ItlyTrackWrapper(trackingEvents.pageViewed);

const viewedTrackIteratively = {
  PAGE_VIEWED,
};

export default viewedTrackIteratively;
