import React from 'react';
import { DARK_COLOR_THEME_NAME, DEFAULT_COLOR_THEME } from '../appConfig';
import { isFastWebappRoute } from '@utils/index';
import { themes } from '@lib/nvstr-utils.es';
import { useRouter } from 'next/router';

const forceDarkThemeRoutes = ['/about', '/contact', '/faq', '/legal'];

const getRouteBasedColorThemeName = (pathname) => {
  if (isFastWebappRoute(pathname)) {
    return DEFAULT_COLOR_THEME;
  }

  if (forceDarkThemeRoutes.includes(pathname)) {
    return DARK_COLOR_THEME_NAME;
  }

  return DEFAULT_COLOR_THEME;
};

export const useColorTheme = (nameOnly) => {
  const { pathname, events } = useRouter();

  const startingColorThemeName = getRouteBasedColorThemeName(pathname);

  const [colorThemeName, setColorThemeName] = React.useState(startingColorThemeName);
  const [colorTheme, setColorTheme] = React.useState(themes[startingColorThemeName]);

  React.useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      const nextColorThemeName = getRouteBasedColorThemeName(url);
      if (nextColorThemeName && nextColorThemeName !== colorThemeName) {
        setColorThemeName(nextColorThemeName);
        setColorTheme(themes[nextColorThemeName]);
      }
    };

    events.on('routeChangeStart', handleRouteChange);
    return () => {
      events.off('routeChangeStart', handleRouteChange);
    };
  }, [colorThemeName]);

  if (nameOnly) return colorThemeName;

  return colorTheme;
};

export default useColorTheme;
