// routes that prevent webapp from mounting
export const PUBLIC_ROUTES = [
  '/about',
  '/enterprise',
  '/contact',
  '/faq',
  '/legal',
  '/margin_disclosure',
  '/privacy_policy',
  '/referrals',
  '/referral_rules',
  '/influencer_disclosure',
  '/rewards_rules',
  '/learn-and-earn-rules',
  '/terms_of_service',
  '/tos',
  '/viewpoint_guidelines',
  '/funding-bonus-rules',
  '/surveyrules',

  '/securities',

  '/lf/a-personalized-money-makeover-for-smarter-money-moves',
];
