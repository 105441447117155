import { learnActionTypes } from '@constants/actionTypes';

const defaultState = {
  lessonData: {},

  // completed_lessons: [],

  nextLessonId: null,
  nextLessonReward: null,
  nextLessonRewardAtXP: null,
  nextLessonCompletionXP: null,
  completedLessonCount: null,
  hoursUntilNextLesson: null,
  status: null,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case learnActionTypes.addLessonData: {
      const data = action.payload;
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          [data.id]: data,
        },
      };
    }

    // case learnActionTypes.updateCompletedLessons: {
    //   const completed_lessons = action.payload;
    //   return {
    //     ...state,
    //     completed_lessons,
    //   };
    // }

    case learnActionTypes.setNextLesson: {
      const {
        nextLessonId,
        nextLessonReward,
        nextLessonRewardAtXP,
        nextLessonCompletionXP,
        completedLessonCount,
        hoursUntilNextLesson,
        messagingStates,
        status,
      } = action.payload;
      return {
        ...state,
        nextLessonId,
        nextLessonReward,
        nextLessonRewardAtXP,
        nextLessonCompletionXP,
        completedLessonCount,
        hoursUntilNextLesson,
        messagingStates,
        status,
      };
    }

    default:
      return state;
  }
}
