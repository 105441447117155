var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
const roll = (odds) => {
  const value = Math.random();
  return value <= odds;
};
function toCapitalCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
const truncate = (str, maxLength, suffix = "...") => {
  if (!str)
    return "";
  if (!(maxLength > 0)) {
    throwError(`Max length for truncate must be greater than 0, received: ${maxLength}`);
    return;
  }
  const suffixLength = suffix.length;
  if (str.length <= maxLength - suffixLength) {
    return str;
  }
  const slicedStr = str.slice(0, maxLength - suffixLength);
  if (slicedStr[slicedStr.length - 1] === " ") {
    return slicedStr.slice(0, slicedStr.length - 1) + suffix;
  } else {
    return slicedStr + suffix;
  }
};
function getContrastTheme(hex, colors) {
  if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    return null;
  }
  let r;
  let g;
  let b;
  if (hex.length == 4) {
    r = parseInt("0x" + hex[1] + hex[1], 16);
    g = parseInt("0x" + hex[2] + hex[2], 16);
    b = parseInt("0x" + hex[3] + hex[3], 16);
  } else {
    r = parseInt("0x" + hex[1] + hex[2], 16);
    g = parseInt("0x" + hex[3] + hex[4], 16);
    b = parseInt("0x" + hex[5] + hex[6], 16);
  }
  let getAdjustedValue = (value) => {
    value /= 255;
    return value <= 0.03928 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4);
  };
  r = getAdjustedValue(r);
  g = getAdjustedValue(g);
  b = getAdjustedValue(b);
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance > 0.179 ? colors.light : colors.dark;
}
const isMobileBrowser = () => {
  let check = false;
  (function(a) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
      a
    ) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      a.substr(0, 4)
    ))
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
const isSafari = () => {
  return navigator.userAgent.indexOf("Safari") !== -1;
};
const hexToRgbA = (hex, a) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return `rgba(${[c >> 16 & 255, c >> 8 & 255, c & 255].join(",")},${a})`;
  }
  return false;
};
const convertHexToRGBA = (hex, a) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return `rgba(${[c >> 16 & 255, c >> 8 & 255, c & 255].join(",")},${a})`;
  }
  return false;
};
const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};
const rgbToHex = (r, g, b) => {
  return "#" + _componentToHex(r) + _componentToHex(g) + _componentToHex(b);
};
function toTitleCase(string) {
  return string.split(" ").map((word) => word[0].toUpperCase() + word.substr(1).toLowerCase()).join(" ");
}
function replaceAll(inputStr, findChar, replaceChar) {
  let result = "";
  for (let i = 0; i < inputStr.length; i++) {
    if (inputStr[i] === findChar) {
      result += replaceChar;
    } else {
      result += inputStr[i];
    }
  }
  return result;
}
const parseToSearchParamsLookup = (searchParams) => {
  const lookup = {};
  for (let key of searchParams.keys()) {
    lookup[key] = searchParams.get(key);
  }
  return lookup;
};
const doArraysContainSameElementValues = (arr1, arr2, config = {}) => {
  const { checkIndexOrderMatches } = config;
  if (arr1.length !== arr2.length) {
    return false;
  }
  if (checkIndexOrderMatches) {
    return arr1.every((el, i) => el === arr2[i]);
  } else {
    return arr1.every((el) => arr2.includes(el));
  }
};
const filterDuplicates = (arr) => {
  return arr.filter(function(item, index) {
    return arr.indexOf(item) === index;
  });
};
const pickOne = (availablePicks = []) => {
  const countOfAvailablePicks = availablePicks.length;
  if (countOfAvailablePicks === 0) {
    return null;
  }
  const randomSelection = Math.floor(Math.random() * countOfAvailablePicks);
  return availablePicks[randomSelection];
};
const pluralize = (count, string, config = {}) => {
  const { prefixCount } = config;
  if (string === "have") {
    return `${prefixCount ? `${count} ` : ""}${count === 1 ? "has" : "have"}`;
  }
  if (string === "need") {
    return `${prefixCount ? `${count} ` : ""}${count === 1 ? "needs" : "need"}`;
  }
  return `${prefixCount ? `${count} ` : ""}${count === 1 ? string : `${string}s`}`;
};
const isInArray = (item, array) => {
  const [key, value] = Object.entries(item)[0];
  array.forEach((el) => {
    const isItem = el[key] === value;
    if (isItem) {
      return true;
    }
  });
  return false;
};
const pickItemWithProbability = (items, probabilityLookup = {}) => {
  if (!Array.isArray(items) || items.length === 0) {
    return null;
  }
  const lessonsWithProbabilities = Object.keys(probabilityLookup);
  const itemsNeedingProbabilities = items.filter((it) => lessonsWithProbabilities.indexOf(it.toString()) < 0);
  const probabilitiesSelectable = Object.entries(probabilityLookup).filter(
    (g) => items.indexOf(parseInt(g[0], 10)) >= 0
  );
  const fixedProbabilityTotal = probabilitiesSelectable.reduce((accumulator2, a) => accumulator2 + a[1], 0);
  if (fixedProbabilityTotal >= 1) {
    console.error("fixed probability is greater than 1, cannot select");
    return null;
  }
  const remainingProbabilityTotal = 1 - fixedProbabilityTotal;
  const selectionValue = Math.random();
  const finalSelectionProbabilities = [];
  let accumulator = 0;
  probabilitiesSelectable.forEach((l) => {
    const prob = l[1];
    accumulator += prob;
    const id = parseInt(l[0], 10);
    finalSelectionProbabilities.push([id, accumulator]);
  });
  const evenDistProb = remainingProbabilityTotal / itemsNeedingProbabilities.length;
  itemsNeedingProbabilities.forEach((l) => {
    accumulator += evenDistProb;
    finalSelectionProbabilities.push([l, accumulator]);
  });
  let selection = null;
  for (let i = 0; i < finalSelectionProbabilities.length; i++) {
    let item = finalSelectionProbabilities[i];
    if (selection === null && selectionValue <= item[1]) {
      selection = parseInt(item[0], 10);
    }
  }
  return selection;
};
const getRandomIntegerInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
const isUndefined = (v) => v === void 0;
const isNull = (v) => v === null;
const isUndefinedOrNull = (v) => isUndefined(v) || isNull(v);
const convertArrayToDisplayString = (array, limit = 0) => {
  if (limit === 1 || array.length === 1) {
    return array[0];
  } else if (limit > 0) {
    const limitedArray = array.slice(0, limit);
    const lastItem = limitedArray.splice(-1, 1);
    return `${limitedArray.join(", ")}, and ${lastItem}`;
  } else {
    const lastItem = array.splice(-1, 1);
    return `${array.join(", ")}, and ${lastItem}`;
  }
};
const doXTimes = (x, func) => {
  for (let i = 0; i < x; i++) {
    func(i);
  }
};
const mapXTimes = (x, func) => {
  const a = [];
  for (let i = 0; i < x; i++) {
    a.push(func(i));
  }
  return a;
};
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
class TrackedRoute {
  constructor(urlPathName, appRouteName, trackingRouteName) {
    __publicField(this, "getTrackingRouteName", () => this.trackingRouteName);
    this._instance_trackedRoute = true;
    this.urlPathName = urlPathName;
    this.appRouteName = appRouteName;
    this.trackingRouteName = trackingRouteName;
  }
}
const appRoutes = {
  PROMO_REDEMPTION_SUCCESS: "Promo Redemption Success",
  NO_AUTH_SPLASH: "No Auth Splash",
  SPLASH: "Splash",
  SPLASH_SIGN_IN: "Splash Sign In",
  SPLASH_SIGN_UP: "Splash Sign Up",
  DEV_TOOLS: "ModeSelect",
  DEV: "Dev",
  AUTHED_APP_INITIALIZATION: "AuthedAppInitialization",
  ONBOARDING_NAVIGATOR: "Onboarding Navigator",
  ONBOARDED_NAVIGATOR: "Onboarded Navigator",
  START_LIVE_TRADING_SIGN_UP: "StartLiveTradingSignUp",
  LT_INVESTING_EXPERIENCE: "LTInvestingExperience",
  NON_ONBOARDING_REGISTER_LIVE_TRADING_START: "Non-onboarding Register Live Trading Start",
  ENTER_NAME: "Enter Name",
  ENTER_ADDRESS: "Enter Address",
  ENTER_PHONE: "Enter Phone",
  INVESTOR_PROFILE: "InvestorProfile",
  IDENTITY: "Identity",
  ENTER_DATE_OF_BIRTH: "Enter Date of Birth",
  DISCLOSURES: "Disclosures",
  DISCLOSURES_EMPLOYMENT: "Disclosures Employment",
  AGREEMENTS: "Agreements",
  LIVE_ACCOUNT_SUBMITTED: "LiveAccountSubmitted",
  LT_REDEEM_REWARDS: "LiveTradingRedeemReferralRewards",
  ONBOARDING_FUNDING: "OnboardingFunding",
  ONBOARDING_ALMOST_THERE: "AlmostThereOnboarding",
  LT_FUNDING_DEPOSIT: "LiveTradingFundingTransfers",
  LT_DEFAULT_EXPECTED_RETURN: "LTDefaultExpectedReturn",
  START_PAPER_TRADING_SIGN_UP: "StartPaperTradingSignUp",
  PT_INVESTING_EXPERIENCE: "PTInvestingExperience",
  PT_DEFAULT_EXPECTED_RETURN: "PTDefaultExpectedReturn",
  START_ONBOARDING: "Onboarding",
  ONBOARDING_ACCOUNT_SELECTION: "OnboardingAccountSelection",
  BROKERAGE_ACCOUNT_OPT_IN: "OnboardingBrokerageAccountOptIn",
  HOME: "Home",
  NO_AUTH_ACTIVITY_FEED: "No Auth Feed",
  ACTIVITY_FEED: "Activity",
  DASHBOARD: "Dashboard",
  TRADE: "Trade",
  FUNDING: "Funding",
  COMMUNITY: "Community",
  TOOLS: "Tools",
  CLOSED_ACCOUNT: "ClosedAccount",
  SEARCH: "Search",
  EXPLORE: "Explore",
  OPTIMIZER: "Optimizer",
  AI_TOOL_SELECT: "AI Tool Select",
  COMPANY_SNAPSHOT: "Company Snapshot",
  COMPANY_SCREENER: "Company Screener",
  QUERY_TOOL: "Query Tool",
  AI_RESULT: "AI Result",
  QUERY_DOCUMENT_SELECTION_MODAL: "Query Doc Selection Modal",
  SECURITY_PAGE: "Security",
  FILTERED_SECURITY_LIST: "FilteredSecurityList",
  SECURITY_LIVE_PRICING: "SecurityLivePricing",
  CREATE_ORDER: "NewOrder",
  CREATE_THOUGHT: "CreateThought",
  FAMOUS_INVESTORS_HOME: "FamousInvestors",
  FAMOUS_INVESTOR_PROFILE: "FamousInvestorProfile",
  CONTACTS_IMPORT: "ContactsImport",
  COMMUNITY_STANDALONE: "CommunityStandalone",
  COMMUNITY_SEARCH: "CommunitySearch",
  REFERRAL_ACTIONS: "ReferralActions",
  CONNECTIONS_LIST: "ConnectionsList",
  REWARDS: "Rewards",
  REDEEM_REWARDS: "RedeemReferralRewards",
  ACCOUNT: "Account",
  ACCOUNT_TRANSACTION_HISTORY: "Account Transaction History",
  LEGAL: "Legal",
  SUPPORT: "Support",
  CREATE_SUPPORT_REQUEST: "CreateSupportRequest",
  USER_SETTINGS: "UserSettings",
  PARTNER_SUBSCRIPTIONS: "PartnerSubscriptions",
  USER_PROFILE: "Profile",
  VIEWPOINT_PROFILE: "Viewpoint Profile",
  VIEWPOINT_EXPLORE: "Explore All Viewpoints",
  EDIT_USER_PROFILE: "EditProfile",
  CHANGE_EMAIL: "ChangeEmail",
  CHANGE_PASSWORD: "ChangePassword",
  UPDATE_PREFERENCES: "UpdatePreferences",
  UPDATE_LIVE_ACCOUNT: "UpdateLiveAccount",
  UPDATE_LIVE_ACCOUNT_FORM: "UpdateLiveAccountForm",
  LIVE_ACCOUNT_FURTHER_DOCUMENTATION_ENTRY: "LiveAccountFurtherDocumentationEntry",
  ONBOARDING_CONTACT_RESUBMIT: "OnboardingContactFormResubmit",
  CONTACT_RESUBMIT: "ContactFormResubmit",
  FUNDING_WITHDRAWAL: "FundingWithdrawal",
  FUNDING_DEPOSIT: "FundingDeposit",
  PAPER_FUNDING_TRANSFERS: "FundingTransferPaper",
  FUNDING_MODAL: "FundingModal",
  LINK_BANK_ACCOUNT: "LinkBankAccountModeSelection",
  LINK_BANK_ACCOUNT_PLAID: "LinkBankAccountPlaid",
  LINK_BANK_ACCOUNT_MICRO: "LinkBankAccountMicro",
  VERIFY_BANK_ACCOUNT: "VerifyBankAccount",
  MANAGE_BANK_ACCOUNTS: "ManageBankAccounts",
  MANAGE_RECURRING_DEPOSITS: "ManageRecurringDeposits",
  CREATE_RECURRING_DEPOSITS: "CreateRecurringDeposits",
  BANK_TRANSFER_HISTORY: "BankTransferHistory",
  DEPOSIT_EXECUTION: "DepositExecution",
  INTERESTS_SELECTION: "InterestsSelection",
  INTEREST_WATCHLIST: "InterestsWatchList",
  PROMPT_PICK_OWN_STOCKS: "PickOwnStocksPrompt",
  CTL_RESPONSE: "CtlResponse",
  TRENDING_STOCKS: "TrendingStocks",
  GETTING_REWARDS_INTERSTITIAL: "GettingRewardsInterstitial",
  AUDIO_LIBRARY: "AudioLibrary",
  NEWS_ARTICLE: "NewsArticle",
  CONTENT_READER: "ContentReader",
  CREATE_SHARE: "CreateShare",
  UPCOMING_EARNINGS: "UpcomingEarnings",
  THOUGHTS_LIST: "ThoughtsList",
  PRIVACY_SETTING_SELECT: "PrivacySettingSelect",
  SITE_DISCLOSURES: "Site Disclosures",
  LEARN: "Learn",
  LEARN_HOME: "Learn Home",
  LEARN_REWARD_EARNED: "Learn Reward Earned",
  LEARN_DISCLAIMERS: "Learn Disclaimers",
  LEARN_LESSONS_COMPLETED: "Learn Lessons Completed",
  FINISH_ACCOUNT_SETUP: "Finish Account Setup",
  TRUSTED_CONTACT: "Trusted Contact",
  PURCHASE_MEMBERSHIP: "Purchase Membership",
  PURCHASE_MEMBERSHIP_PLAN: "Purchase Membership Plan",
  INVESTING_JOURNEY: "Investing Journey",
  VIEWPOINT_WELCOME: "ViewpointWelcome",
  VIEWPOINT_SUBSCRIPTION_MANAGEMENT: "ViewpointSubscriptionManagement",
  VIEWPOINT_SUBSCRIPTION_SUCCESS: "ViewpointSubscriptionSuccess",
  PUSH_NOTIFICATION_DETAIL: "Push Notification Detail",
  ANNUAL_MEMBERSHIP_ACCOUNT_OPT_IN: "Annual Membership Account Opt-in",
  MODAL: "Modal",
  USER_LIST_MODAL: "UserListModal",
  CLOSE_ACCOUNT_MODAL: "CloseAccountModal",
  SKIPPING_SIGNUP_REWARD_MODAL: "SkippingSignupRewardModal",
  MORE_INFO_ON_MARGIN_ACCOUNTS: "MoreInfoOnMarginAccountsModal",
  REMOVE_CONNECTION_MODAL: "RemoveConnectionModal",
  VIEWPOINT_SUBSCRIPTION_CONFIRMATION_MODAL: "ViewpointSubscribeConfirmationModal",
  START_LIVE_TRADING_NOW_ACTION_MODAL: "Start Live Trading Now Action Modal",
  CANCEL_VIEWPOINT_SUBSCRIPTION_MODAL: "CancelViewpointSubscriptionModal",
  INVITE_BY_EMAIL: "AddInviteEmailMessage",
  INFO_ICON_EXPLANATION: "InfoIconExplanation",
  ADD_IDEA_WITH_IDEA_TYPE_SELECTION: "AddIdea-IdeaTypeSelection",
  SECURITY_SCREEN_ACTIONS: "SecurityScreenActions",
  RATE_APP_MODAL: "Rate App Modal",
  OTA_UPDATE_APP_MODAL: "OTA Update Prompt Modal",
  FINISH_ACCOUNT_SETUP_MODAL: "Finish Account Setup Modal",
  DEPOSIT_PROMO_MODAL: "Deposit Promo Modal",
  SELECTION_MODAL: "Selection Modal",
  CONFIRM_EMAIL_ADDRESS_MODAL: "Confirm Email Address Modal",
  DISCLOSURES_MODAL: "Disclosures Modal",
  DONT_MISS_OUT_SIGN_UP_BONUS_MODAL: "Dont Miss Out Sign Up Bonus Modal",
  WITHDRAWAL_FUNDING_BREAKDOWN: "Withdrawal Funding Breakdown Modal",
  BROKERAGE_TRANSFER_MODAL: "Brokerage ACAT Transfer Request Modal",
  ANNUAL_ACCOUNT_UPGRADE_MODAL: "Annual Account Upgrade Modal",
  IOS_DROPDOWN_MODAL: "iOS Dropdown Modal",
  SELECT_SINGLE_FORM_OF_CONTACT: "Select Single Form of Contact Modal",
  SHARE_LEARN_MODAL: "Share Learn Modal",
  ONBOARDING_WELCOME_VIDEO: "Onboarding Welcome Video Modal",
  PROMPT_ENABLE_PUSH_NOTIFICATIONS: "Prompt Enable Push Notifications Modal",
  LOGIN_TYPE_SELECTION_MODAL: "Login Type Selection Modal",
  PORTFOLIO_PRICING_UNAVAILABLE_MODAL: "Portfolio Pricing Unavailable Modal",
  GENERIC_FIXED_BOTTOM_MODAL: "Generic Fixed Bottom Modal"
};
const webappRoutes = {
  security: "/securities/:id",
  thoughtWithSlug: "/thoughts/:id/:thoughtSlug",
  thought: "/thoughts/:id",
  featuredViewpoints: "/viewpoint_contributors/featured",
  manageViewpoints: "/viewpoint_contributors/manage",
  viewpointProfile: "/viewpoint_contributors/:name",
  bdTerms: "/terms",
  socureDocSubmission: "/live-account-submission-status",
  resubmitAddress: "/resubmit-address",
  uploadDocuments: "/upload-required-documents",
  onboardingUploadDocuments: "/live_accounts/documentation",
  liveAccountSubmitConfirmation: "/live_accounts/confirmation",
  onboardingAlmostThere: "/almost-there",
  agreements: "/live_accounts/agreements",
  disclosures: "/live_accounts/disclosures",
  disclosuresEmployment: "/live_accounts/disclosures-employment",
  identity: "/live_accounts/identity",
  contact: "/live_accounts/contact",
  investorProfile: "/live_accounts/investor_profile",
  inviteFriends: "/invite-friends",
  trade: "/orders",
  myIdeas: "/ideas",
  onboardResumeProgress: "/onboard",
  portfolio: "/portfolio",
  portfolioHistory: "/portfolio/history",
  portfolioFunding: "/portfolio/funding",
  funding: "/funding",
  recurringDeposits: "/funding/recurring-deposits",
  createRecurringDeposits: "/funding/schedule-new-recurring-deposit",
  linkBank: "/funding/link-bank",
  withdrawFunds: "/funding/withdraw",
  depositFunds: "/funding/deposit",
  usersSignup: "/users/sign_up",
  login: "/login",
  signup: "/signup",
  editProfile: "/users/edit",
  connections: "/users/connections",
  userProfile: "/users/:id/public_profile",
  thoughtLeaders: "/users/thought_leaders",
  privacySettings: "/privacy_settings",
  ask: "/ask",
  rewards: "/referrals",
  rewardRedemption: "/rewards_redemption",
  learnAndEarn: "/learn-and-earn",
  learnAndEarnProgress: "/learn-and-earn-progress",
  learnAndEarnMoreInfo: "/learn-and-earn-more-info",
  selectAccountType: "/account_type",
  brokerage_account_opt_in: "/brokerage_account",
  investingExperience: "/investing_experience",
  subscriptions: "/subscriptions",
  membership: "/membership",
  portfolioExplorer: "/ideas/portfolio_explorer",
  popularStocks: "/ideas/community",
  inviteFriendsFromPlatform: "/invites/provider",
  inviteFriendsFromPlatformSuccess: "/invites/:network/contact_callback",
  inviteFriendsFromPlatformFailure: "/invites/:network/contact_failure",
  inviteContactsFailure: "/contacts/failure",
  trustedContact: "/trusted-contact",
  legal: "/legal",
  faq: "/faq",
  marginDisclosure: "/margin_disclosure",
  privacyPolicy: "/privacy_policy",
  tos: "/terms_of_service",
  tosNvstrFinancial: "/terms_of_service_nvstr_financial",
  tosNvstrTechnologies: "/terms_of_service_nvstr_technologies",
  referralRules: "/referral_rules",
  viewpointGuidelines: "/viewpoint_guidelines",
  shareLink: "/share/:token",
  eDocuments: "/documents",
  dashboard: "/",
  finishAccountSetup: "/finish-account-setup"
};
const PRODUCT_PLATFORMS = {
  mobileApp: "mobileApp",
  webApp: "webApp"
};
const trackingEvents = {
  identify: "identify",
  accountTypeSelected: "Account Type Selected",
  completeApexAccount: "Complete Apex Account",
  bankLinkSkipped: "Bank Link Skipped",
  buttonClicked: "Button Clicked",
  feedLoaded: "Feed Loaded",
  ideaViewed: "Idea Viewed",
  modalViewed: "Modal Viewed",
  onboardingCompleted: "Onboarding Completed",
  onboardingFundingSkipped: "Onboarding Funding Skipped",
  proConViewed: "Pro/Con Viewed",
  tabViewed: "Tab Viewed",
  pageViewed: "Page Viewed",
  learnLessonViewed: "Learn and Earn Lesson Viewed",
  learnLessonRewardViewed: "Learn and Earn Reward Viewed",
  learnLessonDisclaimerViewed: "Learn and Earn Disclaimer Viewed",
  securityViewed: "Security Viewed",
  signUpCreated: "Sign Up Created",
  userCreated: "User Created",
  viewpointSubscriptionPaid: "Viewpoint Subscription Paid",
  screenViewed: "Screen Viewed",
  bankLinked: "Bank Linked",
  brokerageAccountApproved: "Brokerage Account Approved",
  brokerageAccountCreated: "Brokerage Account Created",
  brokerageAccountSubmitted: "Brokerage Account Submitted",
  creditCardLinked: "Credit Card Linked",
  depositCompleted: "Deposit Completed",
  depositReturned: "Deposit Returned",
  depositSkipped: "Deposit Skipped",
  depositSubmitted: "Deposit Submitted",
  emailConfirmed: "Email Confirmed",
  highEquityReached: "High Equity Reached",
  ideaCopied: "Idea Copied",
  ideaCreated: "Idea Created",
  membershipPaid: "Membership Paid",
  orderCommissionPaid: "Order Commission Paid",
  orderExecuted: "Order Executed",
  orderPlaced: "Order Placed",
  orderScreenViewed: "Order Screen Viewed",
  proConCreated: "Pro/Con Created"
};
const types = {
  string: "string",
  number: "number",
  boolean: "boolean",
  object: "object"
};
class CommonValidationType {
  constructor(type, required, props = {}) {
    __publicField(this, "schema", {});
    __publicField(this, "isRequired", false);
    this.schema = {
      type,
      ...props
    };
    this.isRequired = required;
  }
}
class StringType extends CommonValidationType {
  constructor(required, props = {}) {
    super(types.string, required, props);
  }
}
class NumberType extends CommonValidationType {
  constructor(required, props = {}) {
    super(types.number, required, props);
  }
}
class BooleanType extends CommonValidationType {
  constructor(required, props = {}) {
    super(types.boolean, required, props);
  }
}
class ObjectType {
  constructor(object = {}, config = {}) {
    __publicField(this, "schema", {});
    this.schema = {
      type: types.object,
      properties: Object.entries(object).reduce((acc, cur) => {
        return {
          ...acc,
          [cur[0]]: cur[1].schema
        };
      }, {}),
      ...config,
      required: Object.entries(object).filter(([k, v]) => {
        return v.isRequired;
      }).map(([k]) => k)
    };
  }
}
const getEnumAsArray = (obj) => Object.values(obj);
const account_types = {
  live: "live",
  simulated: "simulated"
};
const account_types_enum$1 = getEnumAsArray(account_types);
const button_types = {
  button: "button",
  link: "link"
};
const button_types_enum$1 = getEnumAsArray(button_types);
const node_location = {
  body: "body",
  footer: "footer",
  header: "header"
};
const node_location_enum$1 = getEnumAsArray(node_location);
const platform = {
  android: "android",
  ios: "ios",
  web: "web"
};
const platform_enum$1 = getEnumAsArray(platform);
const idea_types = {
  buy: "buy",
  sell: "sell"
};
const idea_types_enum$1 = getEnumAsArray(idea_types);
const pro_or_con = {
  con: "con",
  pro: "pro"
};
const pro_or_con_enum$1 = getEnumAsArray(pro_or_con);
var enums = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  getEnumAsArray,
  account_types,
  account_types_enum: account_types_enum$1,
  button_types,
  button_types_enum: button_types_enum$1,
  node_location,
  node_location_enum: node_location_enum$1,
  platform,
  platform_enum: platform_enum$1,
  idea_types,
  idea_types_enum: idea_types_enum$1,
  pro_or_con,
  pro_or_con_enum: pro_or_con_enum$1
}, Symbol.toStringTag, { value: "Module" }));
const { account_types_enum, button_types_enum, node_location_enum, platform_enum, idea_types_enum, pro_or_con_enum } = enums;
const trackingEventsValidationSchema = {
  [trackingEvents.identify]: new ObjectType({}, { additionalProperties: true }).schema,
  [trackingEvents.accountTypeSelected]: new ObjectType({
    "account_type": new StringType(true, {
      enum: account_types_enum
    })
  }, { additionalProperties: false }).schema,
  [trackingEvents.bankLinkSkipped]: {},
  [trackingEvents.buttonClicked]: new ObjectType({
    "button_type": new StringType(true, {
      enum: button_types_enum
    }),
    "context": new StringType(true),
    "description": new StringType(true),
    "position": new StringType(true, {
      enum: node_location_enum
    }),
    "platform": new StringType(true, {
      enum: platform_enum
    })
  }, { "additionalProperties": false }).schema,
  [trackingEvents.feedLoaded]: new ObjectType({
    "item_count": new NumberType(true)
  }).schema,
  [trackingEvents.ideaViewed]: new ObjectType({
    "context": new StringType(true),
    "idea_type": new StringType(true, { enum: idea_types_enum }),
    "ticker_symbol": new StringType(true)
  }, { "additionalProperties": false }).schema,
  [trackingEvents.modalViewed]: new ObjectType({
    "context": new StringType(true),
    "is_error": new BooleanType(true),
    "message": new StringType(true),
    "modal_type": new StringType(true)
  }, { "additionalProperties": false }).schema,
  [trackingEvents.onboardingCompleted]: {},
  [trackingEvents.onboardingFundingSkipped]: {},
  [trackingEvents.pageViewed]: new ObjectType({
    "location": new StringType(true)
  }),
  [trackingEvents.proConViewed]: new ObjectType({
    "context": new StringType(true),
    "pro_or_con": new StringType(true, { enum: pro_or_con_enum }),
    "ticker_symbol": new StringType(true)
  }, { "additionalProperties": false }).schema,
  [trackingEvents.tabViewed]: new ObjectType({
    "context": new StringType(true),
    "platform": new StringType(true, { enum: platform_enum }),
    "position": new StringType(true, { enum: node_location_enum }),
    "description": new StringType(true)
  }, { "additionalProperties": false }).schema
};
const TRACKING_ROUTE_NAMES = {
  securityQuote: "Security",
  thoughtView: "Thought",
  viewAllViewpoints: "View All Viewpoints",
  featuredViewpoints: "Featured Viewpoints",
  viewpointsManagement: "Viewpoints Management",
  viewpointProfile: "Viewpoint Profile",
  bdTerms: "BD Terms",
  socureDocumentUpload: "Socure Document Upload",
  addressResubmit: "Address Resubmit",
  uploadDocuments: "Upload Documents",
  uploadDocumentsOnboarding: "Upload Documents (Onboarding)",
  liveAccountSubmitConfirmation: "Live Account Submit Confirmation",
  onboardingAlmostThere: "Onboarding Almost There",
  funding: "Funding",
  recurringDeposits: "Recurring Deposits",
  enterName: "Enter Name",
  enterContactAddress: "Enter Contact Address",
  enterPhoneNumber: "Enter Phone Number",
  agreements: "Agreements",
  disclosures: "Disclosures",
  disclosuresEmployment: "Disclosures Employment",
  identity: "Identity",
  enterDOB: "Enter Date of Birth",
  contact: "Contact",
  enterCitizenship: "Enter Citizenship",
  investorProfile: "Investor Profile",
  resumeOnboarding: "Resume Onboarding",
  accountTypeSelection: "Account Type Selection",
  investingExperienceSelect: "Investing Experience Select",
  defaultExpectedReturnSelect: "Default Expected Return Select",
  statePaperTradingSignup: "Start Paper Trading Signup",
  closedAccount: "Closed Account",
  search: "Search",
  explore: "Explore",
  filteredSecurityList: "Filtered Security List",
  securityLivePricing: "Security Live Pricing",
  createTrade: "Create Trade",
  createThought: "Create Thought",
  contactsImport: "Contacts Import",
  communitySearch: "Community Search",
  connectionsList: "Connections List",
  accountSettings: "Account Settings",
  userSettings: "User Settings",
  legalLinks: "Legal Links",
  support: "Support",
  createSupportRequest: "Create Support Request",
  changeEmail: "Change Email",
  changePassword: "Change Password",
  updatePreferences: "Update Preferences",
  updateLiveAccount: "Update Live Account",
  linkBankAccountMethodSelection: "Link Bank Account Method Selection",
  linkBankAccountPlaid: "Link Bank Account Plaid",
  linkBankAccountMicro: "Link Bank Account Micro",
  verifyBankAccount: "Verify Bank Account",
  manageBankAccount: "Manage Bank Account",
  interestsSelection: "Interests Selection",
  interestsWatchlist: "Interests Watchlist",
  pickOwnStocksPrompt: "Pick Own Stocks Prompt",
  ctlResponse: "CTL Response",
  trendingStocks: "Trending Stocks",
  fetchingRewards: "Fetching Rewards",
  upcomingEarnings: "Upcoming Earnings",
  thoughtList: "Thought List",
  siteDisclosures: "Site Disclosures",
  pushNotificationDetail: "Push Notification Detail",
  webappDashboard: "Webapp Dashboard",
  portfolio: "Portfolio",
  activityFeed: "Activity Feed",
  trade: "Trade",
  fundingWithdrawal: "Funding Transfer - Withdrawal",
  fundingDeposit: "Funding Transfer - Deposit",
  fundingTransfer: "Funding Transfer",
  fundingTransferOnboarding: "Funding Transfer (Onboarding)",
  enterOptionsExperience: "Enter Options Experience",
  enterSSN: "Enter SSN",
  myIdeas: "My Ideas",
  community: "Community",
  accountHistory: "Account History",
  eDocuments: "E Documents",
  signup: "Signup",
  splash: "Splash",
  login: "Login",
  editUserProfile: "Edit User Profile",
  selectPrivacySetting: "Select Privacy Setting",
  userProfile: "User Profile",
  thoughtLeaders: "Thought Leaders",
  createAsk: "Create Ask",
  allReferralActions: "All Referral Actions",
  rewards: "Rewards",
  redeemRewards: "Redeem Rewards",
  learnAndEarnLesson: "Learn and Earn Lesson",
  learnAndEarnProgress: "Learn and Earn Progress",
  learnAndEarnMoreInfo: "Learn and Earn More Info",
  learnAndEarnRewardEarned: "Learn and Earn Reward Earned",
  annualMembershipOptIn: "Annual Membership Opt-in",
  subscriptionsPayment: "Subscriptions Payment",
  membershipPayment: "Membership Payment",
  inviteFriendsFromPlatform: "Invite Friends From Platform",
  inviteFriendsFromPlatformSuccess: "Invite Friends From Platform - Success",
  inviteFriendsFromPlatformFailure: "Invite Friends From Platform - Failure",
  contactImportFailure: "Contact Import Failure",
  faq: "FAQ",
  marginDisclosure: "Margin Disclosure",
  privacyPolicy: "Privacy Policy",
  tos: "Terms of Service",
  tosNvstrFin: "Terms of Service - Nvstr Financial",
  tosNvstrTech: "Terms of Service - Nvstr Technologies",
  referralRules: "Referral Rules",
  viewpointContributorGuidelines: "Viewpoint Contributor Guidelines",
  shareLinkVisited: "Share Link Visited (Already Logged In)",
  devTools: "Dev Tools",
  dev: "Dev",
  appLoading: "App Loading",
  startLiveTrading: "Start Live Trading",
  promoRedemptionSuccess: "Promo Redemption Success",
  viewpointSubscriptionSuccess: "Viewpoint Subscription Success",
  finishAccountSetup: "Finish Account Setup"
};
const storeActionTypes = {
  currentUser: {
    update: "update-current-user-data"
  },
  colorTheme: {
    update: "update-color-theme"
  },
  karma: {
    add: "add-karma",
    update: "update-karma-total",
    setReachedMax: "set-karma-max-reached"
  },
  learnAndEarn: {
    setNextLesson: "set-next-lesson",
    updateCompletedLessons: "update-completed-lessons"
  },
  learnLessonPreview: {
    reset: "learn-preview-reset",
    updateLearnPreviewLesson: "update-learn-preview-lesson",
    addBlogDisclaimer: "add-blog-disclaimer",
    updateBlogDisclaimer: "update-blog-disclaimer",
    deleteBlogDisclaimer: "delete-blog-disclaimer",
    addDisclaimer: "add-disclaimer",
    updateDisclaimer: "update-disclaimer",
    deleteDisclaimer: "delete-disclaimer",
    update: "update-current-user-data"
  },
  learnLesson: {
    addLessonData: "add-learn-lesson-data"
  }
};
const USER_ACTION_CONTEXTS = {
  learnLesson: "Learn Lesson",
  learnHome: "Learn Home Screen"
};
const USER_ACTION_EVENT_NAMES = {
  pressPartnerLink: "Press Partner Link",
  viewPartnerLesson: "View Partner Lesson",
  viewPartnerLessonBanner: "View Partner Lesson Banner"
};
const PRODUCT_PARTNERS = {
  ladder: "ladder",
  youNeedABudget: "youNeedABudget",
  bestow: "bestow",
  lemonade: "lemonade",
  wagmo: "wagmo",
  vspdirect: "vspdirect",
  newConstructs: "newConstructs",
  upstart: "upstart",
  galat: "galat",
  bankrate: "bankrate",
  covered: "covered"
};
const CUSTOM_LEARN_LESSON_COMPONENT_TYPES = {
  learnDeposit: "learnDeposit",
  learnRecurringDeposit: "learnRecurringDeposit",
  learnCommunity: "learnCommunity",
  learnReferral: "learnReferral"
};
const LEARN_LESSON_TYPES = {
  text: "text",
  custom: "custom"
};
const LEARN_CONTENT_TYPES = {
  text: "text",
  sponsorIntro: "sponsorIntro",
  sponsorOutro: "sponsorOutro",
  productPartnerOutro: "productPartnerOutro",
  ladderQuoteTool: "ladderQuoteTool"
};
const LEARN_STATUS_TYPES = {
  noMoreLessons: "NO_MORE_LESSONS",
  liveAccountAndDepositRequired: "LIVE_ACCOUNT_AND_DEPOSIT_REQUIRED",
  depositRequired: "DEPOSIT_REQUIRED",
  onCooldown: "COME_BACK_TOMORROW"
};
const LEARN_STATUS_MESSAGES = {
  [LEARN_STATUS_TYPES.noMoreLessons]: "More lessons coming soon",
  [LEARN_STATUS_TYPES.liveAccountAndDepositRequired]: "Want to unlock more lessons?\nOpen a live trading account and place your first deposit to complete your account setup.\nPut your knowledge into practice and grow your skills. Invest in yourself and we will, too",
  [LEARN_STATUS_TYPES.depositRequired]: "Want to unlock more lessons?\nPlace your first deposit to complete your account setup.\nPut your knowledge into practice and grow your skills. Invest in yourself and we will, too.",
  [LEARN_STATUS_TYPES.onCooldown]: "Come back tomorrow for new lessons!"
};
const EXTRA_CONTENT_TYPES = {
  security: "security"
};
const COLOR_THEME_NAMES = {
  aiLight: "ai-analyst-light-color-scheme",
  aiDark: "ai-analyst-dark-color-scheme",
  light: "light-color-scheme",
  dark: "dark-color-scheme",
  theStreet: "the-street-color-scheme",
  custom: "custom-color-scheme"
};
const _tornadoColorPalette = {
  primary: {
    charcoal: "#1f1f1f",
    oak: "#F7F4F2",
    white: "#fff",
    whiteBlueTint: "#F1F5F5"
  },
  secondary: {
    yellow: "#fcfe7d",
    navy: "#211e2c",
    black: "#000",
    blackTransparent: "rgba(30,30,30,0.1)",
    white: "#fff",
    whiteTransparent: "rgba(255,255,255,0.3)",
    cyan: "#9ffbf5",
    blue: "#0000FF",
    darkBlue: "#104a72",
    streetBlue: "#0D4D99",
    streetGreen: "#080",
    streetRed: "#ff0000",
    lightBlue: "#7AC9EA",
    purple: "#6d56fb",
    promoPurple: "#5937f9",
    darkPurple: "#201F2C",
    lavender: "#dcdbff",
    red: "#f5665d",
    green: "#7ecb00",
    brightGreen: "#1AD36F",
    brightRed: "#FF5000",
    darkRed: "#bc332c",
    darkGreen: "#067915",
    silver: "#c0c0c0",
    lightCharcoal: "#343434",
    orange: "rgb(255,156,34)",
    cream: "#F6F3EF",
    lighterGrey: "rgb(235,235,235)",
    osGrey: "rgb(218,224,227)",
    cloud: "#777777",
    darkCloud: "#333",
    lightCloud: "#fafafa"
  },
  element: {
    borderLight: "rgba(31,31,31, 0.05)",
    borderDark: "rgba(247,244,242, 0.05)"
  }
};
const tornadoColorPalette = _tornadoColorPalette;
const colorPalette = _tornadoColorPalette;
const { charcoal, oak } = colorPalette.primary;
const {
  white,
  darkCloud,
  lightCloud,
  cloud,
  streetBlue,
  streetGreen,
  streetRed,
  orange,
  purple,
  lightCharcoal,
  green,
  red,
  darkRed,
  brightGreen,
  yellow,
  lightBlue
} = colorPalette.secondary;
const COLOR_THEME_KEYS = {
  header: "header",
  appBackground: "appBackground",
  component: "component",
  border: "border",
  text: "text",
  buttonText: "buttonText",
  proColor: "proColor",
  conColor: "conColor",
  disabled: "disabled",
  overlay: "overlay",
  componentNoOpacity: "componentNoOpacity",
  lowContrastBorder: "lowContrastBorder",
  secondaryText: "secondaryText",
  neutral: "neutral",
  negative: "negative",
  positive: "positive",
  avatarBackgroundColor: "avatarBackgroundColor",
  buttonColor: "buttonColor",
  primaryCtaButton: "primaryCtaButton",
  primaryEyeCatchingCtaButton: "primaryEyeCatchingCtaButton",
  badge: "badge",
  error: "error",
  warning: "warning",
  hoverComponentBackground: "hoverComponentBackground"
};
const CUSTOMIZE_COLOR_THEME_KEYS = [
  COLOR_THEME_KEYS.appBackground,
  COLOR_THEME_KEYS.componentNoOpacity,
  COLOR_THEME_KEYS.text,
  COLOR_THEME_KEYS.primaryCtaButton,
  COLOR_THEME_KEYS.buttonText,
  COLOR_THEME_KEYS.positive,
  COLOR_THEME_KEYS.neutral,
  COLOR_THEME_KEYS.negative,
  COLOR_THEME_KEYS.proColor,
  COLOR_THEME_KEYS.conColor
];
const themes = {
  [COLOR_THEME_NAMES.light]: {
    header: white,
    appBackground: oak,
    componentNoOpacity: white,
    text: charcoal,
    buttonText: white,
    proColor: brightGreen,
    conColor: red,
    neutral: lightCharcoal,
    negative: red,
    positive: brightGreen,
    inputBg: "transparent",
    avatarBackgroundColor: purple,
    buttonColor: purple,
    primaryCtaButton: purple,
    primaryEyeCatchingCtaButton: lightBlue,
    badge: purple,
    error: red,
    warning: orange,
    hoverComponentBackground: convertHexToRGBA(purple, 0.1),
    component: convertHexToRGBA(white, 0.9),
    disabled: convertHexToRGBA(charcoal, 0.05),
    overlay: convertHexToRGBA(charcoal, 0.6),
    border: convertHexToRGBA(charcoal, 0.2),
    lowContrastBorder: convertHexToRGBA(charcoal, 0.05),
    secondaryText: convertHexToRGBA(charcoal, 0.3)
  },
  [COLOR_THEME_NAMES.dark]: {
    header: charcoal,
    appBackground: charcoal,
    component: lightCharcoal,
    componentNoOpacity: convertHexToRGBA(lightCharcoal, 1),
    disabled: convertHexToRGBA(white, 0.05),
    overlay: convertHexToRGBA(oak, 0.4),
    border: convertHexToRGBA(oak, 0.2),
    lowContrastBorder: convertHexToRGBA(oak, 0.05),
    text: white,
    secondaryText: convertHexToRGBA(white, 0.4),
    buttonText: charcoal,
    proColor: brightGreen,
    conColor: red,
    neutral: white,
    negative: red,
    positive: brightGreen,
    inputBg: "transparent",
    badge: yellow,
    avatarBackgroundColor: yellow,
    buttonColor: yellow,
    primaryCtaButton: yellow,
    primaryEyeCatchingCtaButton: lightBlue,
    error: red,
    warning: orange,
    hoverComponentBackground: convertHexToRGBA(yellow, 0.1)
  },
  [COLOR_THEME_NAMES.theStreet]: {
    header: white,
    appBackground: lightCloud,
    darkerAppBackground: cloud,
    component: convertHexToRGBA(white, 0.9),
    componentNoOpacity: convertHexToRGBA(white, 1),
    disabled: convertHexToRGBA(darkCloud, 0.05),
    overlay: convertHexToRGBA(darkCloud, 0.6),
    border: convertHexToRGBA(darkCloud, 0.2),
    lowContrastBorder: convertHexToRGBA(darkCloud, 0.05),
    text: darkCloud,
    secondaryText: convertHexToRGBA(darkCloud, 0.6),
    badge: streetBlue,
    proColor: streetGreen,
    conColor: streetRed,
    neutral: cloud,
    negative: streetRed,
    warning: orange,
    positive: streetGreen,
    avatarBackgroundColor: streetBlue,
    inputBg: "transparent",
    buttonColor: streetBlue,
    primaryCtaButton: streetBlue,
    buttonText: white,
    primaryEyeCatchingCtaButton: streetBlue,
    error: streetRed,
    hoverComponentBackground: convertHexToRGBA(streetBlue, 0.5)
  }
};
const breakpoints = {
  mobile: "768px",
  table: "1024px",
  desktop: "1368px"
};
const SCREEN_SIZES = {
  mobile: "mobile",
  tablet: "tablet",
  desktop: "desktop"
};
const REFERRAL_TYPES = {
  email: "Email",
  shareLink: "Share Link",
  shareMessaging: "Share Messaging",
  manualEmail: "Manual Email",
  facebook: "Facebook",
  twitter: "Twitter",
  linkedIn: "LinkedIn",
  phoneContacts: "Phone Contacts",
  gmailContact: "Gmail Contacts",
  askShare: "Ask Why Share"
};
class TrackedRouteStore {
  constructor() {
    __publicField(this, "addTrackedRoute", (trackedRoute) => {
      if (!trackedRoute._instance_trackedRoute)
        throw Error;
      this.trackedRoutes.push(trackedRoute);
    });
    __publicField(this, "getTrackingNameForWebappRoute", (urlPathName) => {
      const webappTrackedRoutesLookup = this._buildWebappTrackedRoutesLookup();
      if (webappTrackedRoutesLookup[urlPathName])
        return webappTrackedRoutesLookup[urlPathName].trackingRouteName;
      const _mutatable_dirs = urlPathName.split("/");
      if (_mutatable_dirs.length === 1)
        return null;
      _mutatable_dirs[_mutatable_dirs.length - 1] = "*";
      const wildCardPath = _mutatable_dirs.join("/");
      if (webappTrackedRoutesLookup[wildCardPath])
        return webappTrackedRoutesLookup[wildCardPath].trackingRouteName;
      if (_mutatable_dirs.length === 2)
        return null;
      _mutatable_dirs[_mutatable_dirs.length - 2] = "*";
      const doubleWildCardPath = _mutatable_dirs.join("/");
      if (webappTrackedRoutesLookup[doubleWildCardPath])
        return webappTrackedRoutesLookup[doubleWildCardPath].trackingRouteName;
      const modelDetailDirs = urlPathName.split("/");
      modelDetailDirs[_mutatable_dirs.length - 2] = "*";
      const modelDetailWildCardPath = modelDetailDirs.join("/");
      if (webappTrackedRoutesLookup[modelDetailWildCardPath])
        return webappTrackedRoutesLookup[modelDetailWildCardPath].trackingRouteName;
      return null;
    });
    __publicField(this, "getTrackingNameForAppRoute", (routeName) => {
      const matchingTrackedRoutes = this.trackedRoutes.filter((trackedRoute) => trackedRoute.appRouteName === routeName);
      if (matchingTrackedRoutes.length > 1) {
        console.warn("multiple matched routes");
      }
      const matchedRoute = matchingTrackedRoutes[0];
      return (matchedRoute == null ? void 0 : matchedRoute.trackingRouteName) || null;
    });
    __publicField(this, "getTrackingNameForRoute", (route, platform2) => {
      if (platform2 === PRODUCT_PLATFORMS.mobileApp) {
        return this.getTrackingNameForAppRoute(route);
      }
      if (platform2 === PRODUCT_PLATFORMS.webApp) {
        return this.getTrackingNameForWebappRoute(route);
      }
    });
    __publicField(this, "_buildWebappTrackedRoutesLookup", () => {
      const insertWildCard = (url) => {
        const dirs = url.split("/");
        const dirsWithWildCards = dirs.map((dir) => dir[0] === ":" ? "*" : dir);
        return dirsWithWildCards.join("/");
      };
      const routes = [];
      this.trackedRoutes.forEach((trackedRoute) => {
        const webappUrl = trackedRoute.urlPathName;
        if (webappUrl !== null) {
          routes.push(trackedRoute);
        }
      });
      const lookup = {};
      routes.forEach((trackedRoute) => {
        lookup[insertWildCard(trackedRoute.urlPathName)] = trackedRoute;
      });
      return lookup;
    });
    __publicField(this, "_scanForMissingWebappRoutes", () => {
      const missingRoutes = [];
      const { routes: allWebappTrackedRoutes, duplicatedRoutes } = this._getAllWebappTrackedRoutes();
      const allWebappRouteEntries = Object.entries(webappRoutes);
      allWebappRouteEntries.forEach((entry) => {
        const [routeName, route] = entry;
        if (!allWebappTrackedRoutes.includes(route)) {
          missingRoutes.push({ routeName, route });
        }
      });
      return {
        missingRoutes,
        duplicatedRoutes
      };
    });
    __publicField(this, "_scanForMissingAppRoutes", () => {
      const missingRoutes = [];
      const { routes: allAppTrackedRoutes, duplicatedRoutes } = this._getAllAppTrackedRoutes();
      const allAppRouteEntries = Object.entries(appRoutes);
      allAppRouteEntries.forEach((entry) => {
        const [routeName, route] = entry;
        if (!allAppTrackedRoutes.includes(route)) {
          missingRoutes.push({ routeName, route });
        }
      });
      return {
        missingRoutes,
        duplicatedRoutes
      };
    });
    __publicField(this, "_getAllAppTrackedRoutes", () => {
      const routes = [];
      const duplicatedRoutes = [];
      this.trackedRoutes.forEach((trackedRoute) => {
        const webappUrl = trackedRoute.appRouteName;
        if (webappUrl !== null) {
          if (routes.includes(webappUrl)) {
            duplicatedRoutes.push(webappUrl);
          } else {
            routes.push(webappUrl);
          }
        }
      });
      return {
        routes,
        duplicatedRoutes
      };
    });
    __publicField(this, "_getAllWebappTrackedRoutes", () => {
      const routes = [];
      const duplicatedRoutes = [];
      this.trackedRoutes.forEach((trackedRoute) => {
        const webappUrl = trackedRoute.urlPathName;
        if (webappUrl !== null) {
          if (routes.includes(webappUrl)) {
            duplicatedRoutes.push(webappUrl);
          } else {
            routes.push(webappUrl);
          }
        }
      });
      return {
        routes,
        duplicatedRoutes
      };
    });
    this.trackedRoutes = [];
  }
}
const _trackedRouteStore = new TrackedRouteStore();
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.security, appRoutes.SECURITY_PAGE, "Security"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.thoughtWithSlug, null, "Thought"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.thought, null, "Thought"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.VIEWPOINT_EXPLORE, "View All Viewpoints"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.featuredViewpoints, appRoutes.VIEWPOINT_WELCOME, "Featured Viewpoints")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.manageViewpoints, appRoutes.VIEWPOINT_SUBSCRIPTION_MANAGEMENT, "Viewpoints Management")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.viewpointProfile, appRoutes.VIEWPOINT_PROFILE, "Viewpoint Profile")
);
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.bdTerms, null, "BD Terms"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(
    webappRoutes.socureDocSubmission,
    appRoutes.LIVE_ACCOUNT_FURTHER_DOCUMENTATION_ENTRY,
    "Socure Document Upload"
  )
);
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.ONBOARDING_CONTACT_RESUBMIT, "Address Resubmit"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.resubmitAddress, appRoutes.CONTACT_RESUBMIT, "Address Resubmit")
);
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.uploadDocuments, null, "Upload Documents"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.onboardingUploadDocuments, null, "Upload Documents (Onboarding)")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(
    webappRoutes.liveAccountSubmitConfirmation,
    appRoutes.LIVE_ACCOUNT_SUBMITTED,
    "Live Account Submit Confirmation"
  )
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.onboardingAlmostThere, appRoutes.ONBOARDING_ALMOST_THERE, "Onboarding Almost There")
);
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.ONBOARDING_FUNDING, "Funding"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.NON_ONBOARDING_REGISTER_LIVE_TRADING_START, "Non-onboarding Register Live Trading Start"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.ENTER_NAME, "Enter Name"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.ENTER_ADDRESS, "Enter Contact Address"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.ENTER_PHONE, "Enter Phone Number"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.agreements, appRoutes.AGREEMENTS, "Agreements"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.disclosures, appRoutes.DISCLOSURES, "Disclosures"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.disclosuresEmployment, appRoutes.DISCLOSURES_EMPLOYMENT, "Disclosures Employment"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.identity, appRoutes.IDENTITY, "Identity"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.ENTER_DATE_OF_BIRTH, "Enter Date of Birth"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.contact, null, "Contact"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.investorProfile, appRoutes.INVESTOR_PROFILE, "Investor Profile")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.onboardResumeProgress, appRoutes.START_ONBOARDING, "Resume Onboarding")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.selectAccountType, appRoutes.ONBOARDING_ACCOUNT_SELECTION, "Account Type Selection")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.brokerage_account_opt_in, appRoutes.BROKERAGE_ACCOUNT_OPT_IN, "Brokerage Account Opt-In")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.investingExperience, appRoutes.LT_INVESTING_EXPERIENCE, "Investing Experience Select")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.PT_INVESTING_EXPERIENCE, "Investing Experience Select")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.LT_DEFAULT_EXPECTED_RETURN, "Default Expected Return Select")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.PT_DEFAULT_EXPECTED_RETURN, "Default Expected Return Select")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.START_PAPER_TRADING_SIGN_UP, "Start Paper Trading Signup")
);
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CLOSED_ACCOUNT, "Closed Account"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.PARTNER_SUBSCRIPTIONS, "Partner Subscriptions"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.SEARCH, "Search"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.EXPLORE, "Explore"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.FILTERED_SECURITY_LIST, "Filtered Security List"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.SECURITY_LIVE_PRICING, "Security Live Pricing"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CREATE_ORDER, "Create Trade"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CREATE_THOUGHT, "Create Thought"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CONTACTS_IMPORT, "Contacts Import"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.COMMUNITY_SEARCH, "Community Search"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CONNECTIONS_LIST, "Connections List"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.ACCOUNT, "Account Settings"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.USER_SETTINGS, "User Settings"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.legal, appRoutes.LEGAL, "Legal Links"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.SUPPORT, "Support"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CREATE_SUPPORT_REQUEST, "Create Support Request"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CHANGE_EMAIL, "Change Email"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CHANGE_PASSWORD, "Change Password"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.UPDATE_PREFERENCES, "Update Preferences"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.UPDATE_LIVE_ACCOUNT, "Update Live Account"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.UPDATE_LIVE_ACCOUNT_FORM, "Update Live Account Form"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.IOS_DROPDOWN_MODAL, "IOS Dropdown Modal"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.SELECT_SINGLE_FORM_OF_CONTACT, "Select Single Form Of Contact Modal"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.SHARE_LEARN_MODAL, "Share Learn Modal"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.ONBOARDING_WELCOME_VIDEO, "Onboarding Welcome Video Modal"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.PROMPT_ENABLE_PUSH_NOTIFICATIONS, "Prompt Enable Push Notifications Modal"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.LOGIN_TYPE_SELECTION_MODAL, "Login Type Selection Modal"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.PORTFOLIO_PRICING_UNAVAILABLE_MODAL, "Portfolio Pricing Unavailable Modal"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.GENERIC_FIXED_BOTTOM_MODAL, "Generic Fixed Bottom Modal"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.linkBank, appRoutes.LINK_BANK_ACCOUNT, "Link Bank Account Method Selection")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.LINK_BANK_ACCOUNT_PLAID, "Link Bank Account Plaid")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.LINK_BANK_ACCOUNT_MICRO, "Link Bank Account Micro")
);
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.VERIFY_BANK_ACCOUNT, "Verify Bank Account"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.FUNDING_MODAL, "Funding Modal"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.recurringDeposits, appRoutes.MANAGE_RECURRING_DEPOSITS, "Manage Recurring Deposits"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.createRecurringDeposits, appRoutes.CREATE_RECURRING_DEPOSITS, "Create Recurring Deposits"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.MANAGE_BANK_ACCOUNTS, "Manage Bank Account"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.BANK_TRANSFER_HISTORY, "Bank Transfer History"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.DEPOSIT_EXECUTION, "Deposit Execution"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.INTERESTS_SELECTION, "Interests Selection"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.INTEREST_WATCHLIST, "Interests Watchlist"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.PROMPT_PICK_OWN_STOCKS, "Pick Own Stocks Prompt"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CTL_RESPONSE, "CTL Response"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.TRENDING_STOCKS, "Trending Stocks"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.GETTING_REWARDS_INTERSTITIAL, "Fetching Rewards"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.UPCOMING_EARNINGS, "Upcoming Earnings"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.THOUGHTS_LIST, "Thought List"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.AUDIO_LIBRARY, "Audio Library"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.INVESTING_JOURNEY, "Investing Journey"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.NEWS_ARTICLE, "News Article"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CONTENT_READER, "Content Reader"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.SITE_DISCLOSURES, "Site Disclosures"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.PUSH_NOTIFICATION_DETAIL, "Push Notification Detail")
);
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.dashboard, null, "Webapp Dashboard"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.portfolio, appRoutes.DASHBOARD, "Portfolio"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.NO_AUTH_ACTIVITY_FEED, "No Auth Activity Feed"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.ACTIVITY_FEED, "Activity Feed"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.trade, appRoutes.TRADE, "Trade"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.portfolioFunding, appRoutes.FUNDING, "Funding"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.funding, null, "Funding"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.withdrawFunds, appRoutes.FUNDING_WITHDRAWAL, "Funding Transfer - Withdrawal")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.depositFunds, appRoutes.FUNDING_DEPOSIT, "Funding Transfer - Deposit")
);
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.PAPER_FUNDING_TRANSFERS, "Funding Transfer"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.LT_FUNDING_DEPOSIT, "Funding Transfer (Onboarding)")
);
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.myIdeas, null, "My Ideas"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.connections, appRoutes.COMMUNITY, "Community"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.connections, appRoutes.COMMUNITY_STANDALONE, "Community"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.connections, appRoutes.TOOLS, "Tools"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.portfolioHistory, appRoutes.ACCOUNT_TRANSACTION_HISTORY, "Account History")
);
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.eDocuments, null, "E Documents"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.usersSignup, null, "Signup"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.SPLASH, "Splash"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.NO_AUTH_SPLASH, "No Auth Splash"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.login, appRoutes.SPLASH_SIGN_IN, "Login"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.signup, appRoutes.SPLASH_SIGN_UP, "Signup"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.editProfile, appRoutes.EDIT_USER_PROFILE, "Edit User Profile")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.privacySettings, appRoutes.PRIVACY_SETTING_SELECT, "Select Privacy Setting")
);
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.userProfile, appRoutes.USER_PROFILE, "User Profile"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.thoughtLeaders, appRoutes.FAMOUS_INVESTORS_HOME, "Thought Leaders"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.FAMOUS_INVESTOR_PROFILE, "Thought Leader Profile"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.ask, appRoutes.CREATE_SHARE, "Create Ask"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.inviteFriends, appRoutes.REFERRAL_ACTIONS, "All Referral Actions")
);
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.rewards, appRoutes.REWARDS, "Rewards"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.rewardRedemption, appRoutes.REDEEM_REWARDS, "Redeem Rewards")
);
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.LT_REDEEM_REWARDS, "Redeem Rewards"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.learnAndEarn, appRoutes.LEARN, "Learn and Earn Lesson")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.learnAndEarnProgress, appRoutes.LEARN_LESSONS_COMPLETED, "Learn and Earn Progress")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.learnAndEarnMoreInfo, appRoutes.LEARN_DISCLAIMERS, "Learn and Earn More Info")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.LEARN_REWARD_EARNED, "Learn and Earn Reward Earned")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.LEARN_HOME, "Learn and Earn Home")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.ANNUAL_MEMBERSHIP_ACCOUNT_OPT_IN, "Annual Membership Opt-in")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.AI_TOOL_SELECT, "Digital Analyst Tool Select")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.COMPANY_SNAPSHOT, "Company Snapshot")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.COMPANY_SCREENER, "Company Screener")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.QUERY_TOOL, "Digital Analyst Query Tool")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.AI_RESULT, "Digital Analyst Answer")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.QUERY_DOCUMENT_SELECTION_MODAL, "Query Document Selection Modal")
);
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.OPTIMIZER, "Portfolio Optimizer"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.portfolioExplorer, null, "Portfolio Explorer"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.popularStocks, null, "Popular Stocks"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.subscriptions, null, "Subscriptions Payment"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.membership, null, "Membership Payment"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.inviteFriendsFromPlatform, null, "Invite Friends From Platform")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.inviteFriendsFromPlatformSuccess, null, "Invite Friends From Platform - Success")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.inviteFriendsFromPlatformFailure, null, "Invite Friends From Platform - Failure")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.inviteContactsFailure, null, "Contact Import Failure")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.trustedContact, appRoutes.TRUSTED_CONTACT, "Trusted Contact Form")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.PURCHASE_MEMBERSHIP, "Purchase Membership")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.PURCHASE_MEMBERSHIP_PLAN, "Purchase Membership Plan")
);
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.faq, null, "FAQ"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.marginDisclosure, null, "Margin Disclosure"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.privacyPolicy, null, "Privacy Policy"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.tos, null, "Terms of Service"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.tosNvstrFinancial, null, "Terms of Service - Nvstr Financial")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.tosNvstrTechnologies, null, "Terms of Service - Nvstr Technologies")
);
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.referralRules, null, "Referral Rules"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.viewpointGuidelines, null, "Viewpoint Contributor Guidelines")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.shareLink, null, "Share Link Visited (Already Logged In)")
);
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.DEV_TOOLS, "Dev Tools"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.DEV, "Dev"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.AUTHED_APP_INITIALIZATION, "App Loading"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.START_LIVE_TRADING_SIGN_UP, "Start Live Trading"));
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.PROMO_REDEMPTION_SUCCESS, "Promo Redemption Success")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(null, appRoutes.VIEWPOINT_SUBSCRIPTION_SUCCESS, "Viewpoint Subscription Success")
);
_trackedRouteStore.addTrackedRoute(
  new TrackedRoute(webappRoutes.finishAccountSetup, appRoutes.FINISH_ACCOUNT_SETUP, "Finish Account Setup")
);
const trackedRouteStore = _trackedRouteStore;
var commonjsGlobal = typeof globalThis !== "undefined" ? globalThis : typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : {};
var dayjs_min = { exports: {} };
(function(module, exports) {
  !function(t, e) {
    module.exports = e();
  }(commonjsGlobal, function() {
    var t = 1e3, e = 6e4, n = 36e5, r = "millisecond", i = "second", s = "minute", u = "hour", a = "day", o = "week", f = "month", h = "quarter", c = "year", d = "date", l = "Invalid Date", $ = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, y = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, M = { name: "en", weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"), ordinal: function(t2) {
      var e2 = ["th", "st", "nd", "rd"], n2 = t2 % 100;
      return "[" + t2 + (e2[(n2 - 20) % 10] || e2[n2] || e2[0]) + "]";
    } }, m = function(t2, e2, n2) {
      var r2 = String(t2);
      return !r2 || r2.length >= e2 ? t2 : "" + Array(e2 + 1 - r2.length).join(n2) + t2;
    }, v = { s: m, z: function(t2) {
      var e2 = -t2.utcOffset(), n2 = Math.abs(e2), r2 = Math.floor(n2 / 60), i2 = n2 % 60;
      return (e2 <= 0 ? "+" : "-") + m(r2, 2, "0") + ":" + m(i2, 2, "0");
    }, m: function t2(e2, n2) {
      if (e2.date() < n2.date())
        return -t2(n2, e2);
      var r2 = 12 * (n2.year() - e2.year()) + (n2.month() - e2.month()), i2 = e2.clone().add(r2, f), s2 = n2 - i2 < 0, u2 = e2.clone().add(r2 + (s2 ? -1 : 1), f);
      return +(-(r2 + (n2 - i2) / (s2 ? i2 - u2 : u2 - i2)) || 0);
    }, a: function(t2) {
      return t2 < 0 ? Math.ceil(t2) || 0 : Math.floor(t2);
    }, p: function(t2) {
      return { M: f, y: c, w: o, d: a, D: d, h: u, m: s, s: i, ms: r, Q: h }[t2] || String(t2 || "").toLowerCase().replace(/s$/, "");
    }, u: function(t2) {
      return void 0 === t2;
    } }, g = "en", D = {};
    D[g] = M;
    var p = function(t2) {
      return t2 instanceof _;
    }, S = function t2(e2, n2, r2) {
      var i2;
      if (!e2)
        return g;
      if ("string" == typeof e2) {
        var s2 = e2.toLowerCase();
        D[s2] && (i2 = s2), n2 && (D[s2] = n2, i2 = s2);
        var u2 = e2.split("-");
        if (!i2 && u2.length > 1)
          return t2(u2[0]);
      } else {
        var a2 = e2.name;
        D[a2] = e2, i2 = a2;
      }
      return !r2 && i2 && (g = i2), i2 || !r2 && g;
    }, w = function(t2, e2) {
      if (p(t2))
        return t2.clone();
      var n2 = "object" == typeof e2 ? e2 : {};
      return n2.date = t2, n2.args = arguments, new _(n2);
    }, O = v;
    O.l = S, O.i = p, O.w = function(t2, e2) {
      return w(t2, { locale: e2.$L, utc: e2.$u, x: e2.$x, $offset: e2.$offset });
    };
    var _ = function() {
      function M2(t2) {
        this.$L = S(t2.locale, null, true), this.parse(t2);
      }
      var m2 = M2.prototype;
      return m2.parse = function(t2) {
        this.$d = function(t3) {
          var e2 = t3.date, n2 = t3.utc;
          if (null === e2)
            return new Date(NaN);
          if (O.u(e2))
            return new Date();
          if (e2 instanceof Date)
            return new Date(e2);
          if ("string" == typeof e2 && !/Z$/i.test(e2)) {
            var r2 = e2.match($);
            if (r2) {
              var i2 = r2[2] - 1 || 0, s2 = (r2[7] || "0").substring(0, 3);
              return n2 ? new Date(Date.UTC(r2[1], i2, r2[3] || 1, r2[4] || 0, r2[5] || 0, r2[6] || 0, s2)) : new Date(r2[1], i2, r2[3] || 1, r2[4] || 0, r2[5] || 0, r2[6] || 0, s2);
            }
          }
          return new Date(e2);
        }(t2), this.$x = t2.x || {}, this.init();
      }, m2.init = function() {
        var t2 = this.$d;
        this.$y = t2.getFullYear(), this.$M = t2.getMonth(), this.$D = t2.getDate(), this.$W = t2.getDay(), this.$H = t2.getHours(), this.$m = t2.getMinutes(), this.$s = t2.getSeconds(), this.$ms = t2.getMilliseconds();
      }, m2.$utils = function() {
        return O;
      }, m2.isValid = function() {
        return !(this.$d.toString() === l);
      }, m2.isSame = function(t2, e2) {
        var n2 = w(t2);
        return this.startOf(e2) <= n2 && n2 <= this.endOf(e2);
      }, m2.isAfter = function(t2, e2) {
        return w(t2) < this.startOf(e2);
      }, m2.isBefore = function(t2, e2) {
        return this.endOf(e2) < w(t2);
      }, m2.$g = function(t2, e2, n2) {
        return O.u(t2) ? this[e2] : this.set(n2, t2);
      }, m2.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, m2.valueOf = function() {
        return this.$d.getTime();
      }, m2.startOf = function(t2, e2) {
        var n2 = this, r2 = !!O.u(e2) || e2, h2 = O.p(t2), l2 = function(t3, e3) {
          var i2 = O.w(n2.$u ? Date.UTC(n2.$y, e3, t3) : new Date(n2.$y, e3, t3), n2);
          return r2 ? i2 : i2.endOf(a);
        }, $2 = function(t3, e3) {
          return O.w(n2.toDate()[t3].apply(n2.toDate("s"), (r2 ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(e3)), n2);
        }, y2 = this.$W, M3 = this.$M, m3 = this.$D, v2 = "set" + (this.$u ? "UTC" : "");
        switch (h2) {
          case c:
            return r2 ? l2(1, 0) : l2(31, 11);
          case f:
            return r2 ? l2(1, M3) : l2(0, M3 + 1);
          case o:
            var g2 = this.$locale().weekStart || 0, D2 = (y2 < g2 ? y2 + 7 : y2) - g2;
            return l2(r2 ? m3 - D2 : m3 + (6 - D2), M3);
          case a:
          case d:
            return $2(v2 + "Hours", 0);
          case u:
            return $2(v2 + "Minutes", 1);
          case s:
            return $2(v2 + "Seconds", 2);
          case i:
            return $2(v2 + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, m2.endOf = function(t2) {
        return this.startOf(t2, false);
      }, m2.$set = function(t2, e2) {
        var n2, o2 = O.p(t2), h2 = "set" + (this.$u ? "UTC" : ""), l2 = (n2 = {}, n2[a] = h2 + "Date", n2[d] = h2 + "Date", n2[f] = h2 + "Month", n2[c] = h2 + "FullYear", n2[u] = h2 + "Hours", n2[s] = h2 + "Minutes", n2[i] = h2 + "Seconds", n2[r] = h2 + "Milliseconds", n2)[o2], $2 = o2 === a ? this.$D + (e2 - this.$W) : e2;
        if (o2 === f || o2 === c) {
          var y2 = this.clone().set(d, 1);
          y2.$d[l2]($2), y2.init(), this.$d = y2.set(d, Math.min(this.$D, y2.daysInMonth())).$d;
        } else
          l2 && this.$d[l2]($2);
        return this.init(), this;
      }, m2.set = function(t2, e2) {
        return this.clone().$set(t2, e2);
      }, m2.get = function(t2) {
        return this[O.p(t2)]();
      }, m2.add = function(r2, h2) {
        var d2, l2 = this;
        r2 = Number(r2);
        var $2 = O.p(h2), y2 = function(t2) {
          var e2 = w(l2);
          return O.w(e2.date(e2.date() + Math.round(t2 * r2)), l2);
        };
        if ($2 === f)
          return this.set(f, this.$M + r2);
        if ($2 === c)
          return this.set(c, this.$y + r2);
        if ($2 === a)
          return y2(1);
        if ($2 === o)
          return y2(7);
        var M3 = (d2 = {}, d2[s] = e, d2[u] = n, d2[i] = t, d2)[$2] || 1, m3 = this.$d.getTime() + r2 * M3;
        return O.w(m3, this);
      }, m2.subtract = function(t2, e2) {
        return this.add(-1 * t2, e2);
      }, m2.format = function(t2) {
        var e2 = this, n2 = this.$locale();
        if (!this.isValid())
          return n2.invalidDate || l;
        var r2 = t2 || "YYYY-MM-DDTHH:mm:ssZ", i2 = O.z(this), s2 = this.$H, u2 = this.$m, a2 = this.$M, o2 = n2.weekdays, f2 = n2.months, h2 = function(t3, n3, i3, s3) {
          return t3 && (t3[n3] || t3(e2, r2)) || i3[n3].slice(0, s3);
        }, c2 = function(t3) {
          return O.s(s2 % 12 || 12, t3, "0");
        }, d2 = n2.meridiem || function(t3, e3, n3) {
          var r3 = t3 < 12 ? "AM" : "PM";
          return n3 ? r3.toLowerCase() : r3;
        }, $2 = { YY: String(this.$y).slice(-2), YYYY: this.$y, M: a2 + 1, MM: O.s(a2 + 1, 2, "0"), MMM: h2(n2.monthsShort, a2, f2, 3), MMMM: h2(f2, a2), D: this.$D, DD: O.s(this.$D, 2, "0"), d: String(this.$W), dd: h2(n2.weekdaysMin, this.$W, o2, 2), ddd: h2(n2.weekdaysShort, this.$W, o2, 3), dddd: o2[this.$W], H: String(s2), HH: O.s(s2, 2, "0"), h: c2(1), hh: c2(2), a: d2(s2, u2, true), A: d2(s2, u2, false), m: String(u2), mm: O.s(u2, 2, "0"), s: String(this.$s), ss: O.s(this.$s, 2, "0"), SSS: O.s(this.$ms, 3, "0"), Z: i2 };
        return r2.replace(y, function(t3, e3) {
          return e3 || $2[t3] || i2.replace(":", "");
        });
      }, m2.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, m2.diff = function(r2, d2, l2) {
        var $2, y2 = O.p(d2), M3 = w(r2), m3 = (M3.utcOffset() - this.utcOffset()) * e, v2 = this - M3, g2 = O.m(this, M3);
        return g2 = ($2 = {}, $2[c] = g2 / 12, $2[f] = g2, $2[h] = g2 / 3, $2[o] = (v2 - m3) / 6048e5, $2[a] = (v2 - m3) / 864e5, $2[u] = v2 / n, $2[s] = v2 / e, $2[i] = v2 / t, $2)[y2] || v2, l2 ? g2 : O.a(g2);
      }, m2.daysInMonth = function() {
        return this.endOf(f).$D;
      }, m2.$locale = function() {
        return D[this.$L];
      }, m2.locale = function(t2, e2) {
        if (!t2)
          return this.$L;
        var n2 = this.clone(), r2 = S(t2, e2, true);
        return r2 && (n2.$L = r2), n2;
      }, m2.clone = function() {
        return O.w(this.$d, this);
      }, m2.toDate = function() {
        return new Date(this.valueOf());
      }, m2.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, m2.toISOString = function() {
        return this.$d.toISOString();
      }, m2.toString = function() {
        return this.$d.toUTCString();
      }, M2;
    }(), T = _.prototype;
    return w.prototype = T, [["$ms", r], ["$s", i], ["$m", s], ["$H", u], ["$W", a], ["$M", f], ["$y", c], ["$D", d]].forEach(function(t2) {
      T[t2[1]] = function(e2) {
        return this.$g(e2, t2[0], t2[1]);
      };
    }), w.extend = function(t2, e2) {
      return t2.$i || (t2(e2, _, w), t2.$i = true), w;
    }, w.locale = S, w.isDayjs = p, w.unix = function(t2) {
      return w(1e3 * t2);
    }, w.en = D[g], w.Ls = D, w.p = {}, w;
  });
})(dayjs_min);
var dayjs = dayjs_min.exports;
var relativeTime$1 = { exports: {} };
(function(module, exports) {
  !function(r, e) {
    module.exports = e();
  }(commonjsGlobal, function() {
    return function(r, e, t) {
      r = r || {};
      var n = e.prototype, o = { future: "in %s", past: "%s ago", s: "a few seconds", m: "a minute", mm: "%d minutes", h: "an hour", hh: "%d hours", d: "a day", dd: "%d days", M: "a month", MM: "%d months", y: "a year", yy: "%d years" };
      function i(r2, e2, t2, o2) {
        return n.fromToBase(r2, e2, t2, o2);
      }
      t.en.relativeTime = o, n.fromToBase = function(e2, n2, i2, d2, u) {
        for (var f, a, s, l = i2.$locale().relativeTime || o, h = r.thresholds || [{ l: "s", r: 44, d: "second" }, { l: "m", r: 89 }, { l: "mm", r: 44, d: "minute" }, { l: "h", r: 89 }, { l: "hh", r: 21, d: "hour" }, { l: "d", r: 35 }, { l: "dd", r: 25, d: "day" }, { l: "M", r: 45 }, { l: "MM", r: 10, d: "month" }, { l: "y", r: 17 }, { l: "yy", d: "year" }], m = h.length, c = 0; c < m; c += 1) {
          var y = h[c];
          y.d && (f = d2 ? t(e2).diff(i2, y.d, true) : i2.diff(e2, y.d, true));
          var p = (r.rounding || Math.round)(Math.abs(f));
          if (s = f > 0, p <= y.r || !y.r) {
            p <= 1 && c > 0 && (y = h[c - 1]);
            var v = l[y.l];
            u && (p = u("" + p)), a = "string" == typeof v ? v.replace("%d", p) : v(p, n2, y.l, s);
            break;
          }
        }
        if (n2)
          return a;
        var M = s ? l.future : l.past;
        return "function" == typeof M ? M(a) : M.replace("%s", a);
      }, n.to = function(r2, e2) {
        return i(r2, e2, this, true);
      }, n.from = function(r2, e2) {
        return i(r2, e2, this);
      };
      var d = function(r2) {
        return r2.$u ? t.utc() : t();
      };
      n.toNow = function(r2) {
        return this.to(d(this), r2);
      }, n.fromNow = function(r2) {
        return this.from(d(this), r2);
      };
    };
  });
})(relativeTime$1);
var relativeTime = relativeTime$1.exports;
var advancedFormat$1 = { exports: {} };
(function(module, exports) {
  !function(e, t) {
    module.exports = t();
  }(commonjsGlobal, function() {
    return function(e, t) {
      var r = t.prototype, n = r.format;
      r.format = function(e2) {
        var t2 = this, r2 = this.$locale();
        if (!this.isValid())
          return n.bind(this)(e2);
        var s = this.$utils(), a = (e2 || "YYYY-MM-DDTHH:mm:ssZ").replace(/\[([^\]]+)]|Q|wo|ww|w|WW|W|zzz|z|gggg|GGGG|Do|X|x|k{1,2}|S/g, function(e3) {
          switch (e3) {
            case "Q":
              return Math.ceil((t2.$M + 1) / 3);
            case "Do":
              return r2.ordinal(t2.$D);
            case "gggg":
              return t2.weekYear();
            case "GGGG":
              return t2.isoWeekYear();
            case "wo":
              return r2.ordinal(t2.week(), "W");
            case "w":
            case "ww":
              return s.s(t2.week(), "w" === e3 ? 1 : 2, "0");
            case "W":
            case "WW":
              return s.s(t2.isoWeek(), "W" === e3 ? 1 : 2, "0");
            case "k":
            case "kk":
              return s.s(String(0 === t2.$H ? 24 : t2.$H), "k" === e3 ? 1 : 2, "0");
            case "X":
              return Math.floor(t2.$d.getTime() / 1e3);
            case "x":
              return t2.$d.getTime();
            case "z":
              return "[" + t2.offsetName() + "]";
            case "zzz":
              return "[" + t2.offsetName("long") + "]";
            default:
              return e3;
          }
        });
        return n.bind(this)(a);
      };
    };
  });
})(advancedFormat$1);
var advancedFormat = advancedFormat$1.exports;
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
const moment = dayjs;
const createTimeInstance = (v) => {
  if (v === null) {
    return null;
  }
  return moment(v);
};
const parseDate = (date) => moment(date);
const isDateBefore = (dateBefore, date) => dateBefore.isBefore(date);
const setDateBack = (length, value) => moment().subtract(length, value);
const formatLocalizedDateTime = (format, moment2) => {
  if (moment2 === null || moment2 === void 0) {
    return null;
  }
  const lookup = {
    Do: "Do",
    api: "YYYY[-]MM[-]DD",
    api2: "MM[-]DD[-]YYYY",
    MD: "MMMM Do",
    LT: "h[:]mm A",
    LTS: "h[:]mm[:]ss A",
    l: "M[/]D[/]YYYY",
    ls: "M[/]D[/]YY",
    ll: "MMMM D[,] YYYY",
    ll2: "MMM D[,] YYYY",
    lll: "MMM D[,]YYYY h[:]mm A",
    llll: "ddd[,] MMM D[,]YYYY h[:]mm[:]ss A",
    "-l": "M[-]D[-]YYYY",
    L: "MM[/]DD[/]YYYY",
    LL: "MMMM Do[,] YYYY",
    LLL: "MMMM[/]DD[/]YYYY h[:]mm A",
    "-L": "MM[-]DD[-]YYYY",
    "-LL": "MMMM[-]DD[-]YYYY",
    "-LLL": "MMMM[-]DD[-]YYYY h[:]mm A",
    day: "dddd",
    "day-date": "dddd[,] MMMM D[,] YYYY",
    LLLL: "dddd[,] MMMM D[,]YYYY h[:]mm A",
    YYYY: "YYYY",
    asOf: "MMM DD[,] YYYY h[:]mm A"
  };
  const dayjsFormat = lookup[format];
  return moment2.format(dayjsFormat);
};
const parseAPIDate = (value) => {
  if (value instanceof Date) {
    return value;
  }
  if (!value) {
    return null;
  }
  try {
    const apiDate = value.split("-");
    const [year, month, day] = apiDate;
    const date = new Date(year, month - 1, day);
    return createTimeInstance(date).toDate();
  } catch (error) {
    return null;
  }
};
const formatToUntilMessage = (timeInstance) => {
  const timeStr = formatLocalizedDateTime("LT", timeInstance);
  const dateStr = timeInstance.format("MMMM Do");
  return `${timeStr} EST on ${dateStr}`;
};
const getWeekNumber = (date) => {
  var oneJan = new Date(date.getFullYear(), 0, 1);
  var numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1e3));
  return Math.ceil((date.getDay() + 1 + numberOfDays) / 7);
};
const returnThisYear = () => {
  const timeNow = moment();
  return timeNow.format("YYYY");
};
const returnThisMonth = () => {
  const timeNow = moment();
  return timeNow.format("MMM");
};
const returnThisDay = () => {
  const timeNow = moment();
  return timeNow.format("D");
};
const returnLastYear = () => {
  const timeNow = moment();
  const timeLastYear = timeNow.subtract(1, "year");
  const lastYearStr = timeLastYear.format("YYYY");
  return lastYearStr;
};
const getTimeNowEpoch = () => moment().valueOf();
const getStartOfDayTodayEpochValue = () => new Date().setHours(0, 0, 0, 0);
const getDaysSinceDate = (date) => {
  const sinceDate = moment(date);
  const today = moment();
  return today.diff(sinceDate, "days");
};
const getDaysUntilDate = (date) => {
  const futureDate = moment(date);
  const today = moment();
  return futureDate.diff(today, "days");
};
const isWithinTimeLength = (msTimeLength, time) => {
  const timeNow = createTimeInstance();
  const timeToCheckAgainst = createTimeInstance(time);
  const expirationTime = timeToCheckAgainst.valueOf() + msTimeLength;
  return timeNow.isBefore(expirationTime);
};
const isAfterTimeLength = (msTimeLength, time) => {
  const timeNow = createTimeInstance();
  const timeToCheckAgainst = createTimeInstance(time);
  const expirationTime = timeToCheckAgainst.valueOf() + msTimeLength;
  return timeNow.isAfter(expirationTime);
};
const getPrevDay = (dayCodeInt) => {
  const dayNow = parseInt(createTimeInstance().format("d"), 10);
  if (dayNow === dayCodeInt) {
    return createTimeInstance().day(dayCodeInt - 7);
  } else {
    return createTimeInstance().day(dayCodeInt);
  }
};
const isFloat = (n) => {
  return n === +n && n !== (n | 0);
};
const getDecimalAccuracy = (paramValue) => {
  if (isNaN(paramValue))
    return 0;
  const v = typeof paramValue !== "string" ? paramValue.toString() : paramValue;
  if (v.indexOf(".") < 0)
    return 0;
  const decimalValue = v.split(".")[1];
  return (decimalValue == null ? void 0 : decimalValue.length) || 0;
};
const DISPLAY_FORMAT_TYPES = {
  STOCK_PRICE: "stock price",
  PRICE: "price",
  INTEGER: "integer",
  COMMA_SEPARATED_INT: "comma separated integer",
  PERCENTAGE: "percentage",
  PERCENTAGE_CHANGE: "percentage change",
  RATIO: "ratio",
  DATE: "date",
  DAY: "day",
  SHARES: "shares",
  REWARD_AMOUNT: "rewardAmount"
};
const formatNumber = (num, options) => {
  return new Intl.NumberFormat("en-US", options).format(num);
};
const _formatter = {
  [DISPLAY_FORMAT_TYPES.PRICE]: (value, config) => {
    const { prefix, stricterDecimals, showPlus, hideDollarSign, asInt } = config;
    const isNegative = value < 0;
    const absValue = Math.abs(value);
    const minimumFractionDigits = asInt ? 0 : 2;
    const maximumFractionDigits = asInt ? 0 : value < 1 ? stricterDecimals && value < 0.01 ? 4 : 2 : 2;
    let displayStr = prefix || "";
    displayStr += isNegative ? "-" : showPlus ? "+" : "";
    displayStr += hideDollarSign ? "" : "$";
    displayStr += formatNumber(absValue, {
      minimumFractionDigits,
      maximumFractionDigits
    });
    return displayStr;
  },
  [DISPLAY_FORMAT_TYPES.STOCK_PRICE]: (value, config) => {
    const { prefix, stricterDecimals, showPlus, hideDollarSign, asInt } = config;
    const isNegative = value < 0;
    const absValue = Math.abs(value);
    const minimumFractionDigits = asInt ? 0 : 2;
    const maximumFractionDigits = asInt ? 0 : value < 1 ? stricterDecimals && value < 0.01 ? 4 : 2 : 2;
    let displayStr = prefix || "";
    displayStr += isNegative ? "-" : showPlus ? "+" : "";
    displayStr += formatNumber(absValue, {
      minimumFractionDigits,
      maximumFractionDigits
    });
    return displayStr;
  },
  [DISPLAY_FORMAT_TYPES.REWARD_AMOUNT]: (value, config) => {
    const { prefix, showPlus, hideDollarSign } = config;
    const isNegative = value < 0;
    const absValue = Math.abs(value);
    let displayStr = prefix || "";
    displayStr += isNegative ? "-" : showPlus ? "+" : "";
    displayStr += hideDollarSign ? "" : "$";
    if (isFloat(value)) {
      displayStr += formatNumber(absValue, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    } else {
      displayStr += formatNumber(absValue, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
    }
    return displayStr;
  },
  [DISPLAY_FORMAT_TYPES.INTEGER]: (value) => {
    const minimumFractionDigits = 0;
    const maximumFractionDigits = 0;
    return formatNumber(value, {
      minimumFractionDigits,
      maximumFractionDigits
    });
  },
  [DISPLAY_FORMAT_TYPES.COMMA_SEPARATED_INT]: (value) => {
    const minimumFractionDigits = 0;
    const maximumFractionDigits = 0;
    return formatNumber(value, {
      minimumFractionDigits,
      maximumFractionDigits
    });
  },
  [DISPLAY_FORMAT_TYPES.RATIO]: (value) => {
    const minimumFractionDigits = 0;
    const maximumFractionDigits = 1;
    return formatNumber(value, {
      minimumFractionDigits,
      maximumFractionDigits
    });
  },
  [DISPLAY_FORMAT_TYPES.PERCENTAGE]: (value) => {
    const minimumFractionDigits = 0;
    const maximumFractionDigits = 2;
    return `${formatNumber(value, {
      minimumFractionDigits,
      maximumFractionDigits
    })}%`;
  },
  [DISPLAY_FORMAT_TYPES.PERCENTAGE_CHANGE]: (value) => {
    const minimumFractionDigits = 0;
    const maximumFractionDigits = 2;
    const isNegative = value < 0;
    return `${isNegative ? "-" : "+"}${formatNumber(Math.abs(value), {
      minimumFractionDigits,
      maximumFractionDigits
    })}%`;
  },
  [DISPLAY_FORMAT_TYPES.SHARES]: (value) => {
    const minimumFractionDigits = 0;
    const maximumFractionDigits = 5;
    return `${formatNumber(value, {
      minimumFractionDigits,
      maximumFractionDigits
    })} sh`;
  },
  [DISPLAY_FORMAT_TYPES.DATE]: (timeInstance) => {
    return formatLocalizedDateTime("ll", timeInstance);
  },
  [DISPLAY_FORMAT_TYPES.DAY]: (timeInstance) => {
    return formatLocalizedDateTime("Do", timeInstance);
  }
};
const formatValueTo = (formatType, value, config = {}) => {
  if (!(formatType in _formatter)) {
    console.error("Missing type", { formatType });
    return "";
  }
  if (isNaN(value)) {
    return "";
  }
  return _formatter[formatType](value, config);
};
const formatDateValueTo = (value, config = {}) => {
  const { prefix, suffix, type } = config;
  const timeInstance = createTimeInstance(value);
  let formattedDateValueForDisplay = null;
  if (type === "untilDate") {
    formattedDateValueForDisplay = formatToUntilMessage(timeInstance);
  } else if (type === "time") {
    formattedDateValueForDisplay = formatLocalizedDateTime("LTS", timeInstance);
  } else if (type === "shortDate") {
    formattedDateValueForDisplay = formatLocalizedDateTime("ll", timeInstance);
  } else {
    formattedDateValueForDisplay = formatLocalizedDateTime("lll", timeInstance);
  }
  let displayStr = prefix || "";
  displayStr += formattedDateValueForDisplay;
  displayStr += suffix || "";
  return displayStr;
};
const buildLessonParagraphs = (body) => {
  const paragraphs = [[]];
  body.forEach((d) => {
    const { isNewLine, isPaddedNewLine } = d;
    if (isNewLine || isPaddedNewLine) {
      paragraphs.push([d]);
    } else {
      paragraphs[paragraphs.length - 1].push(d);
    }
  });
  return paragraphs;
};
const slugifyLessonName = (name) => {
  let ename = name.toLowerCase().replace(". ", "-");
  ename = ename.replace(".", "");
  ename = ename.replace(".", "");
  ename = ename.replace(" - ", "-");
  ename = ename.replace(" - ", "-");
  ename = ename.replace(" & ", "-");
  ename = ename.replace(", ", "-");
  ename = ename.replace(", ", "-");
  ename = ename.replace(", ", "-");
  ename = ename.replace(",", "-");
  ename = ename.replace(",", "-");
  ename = ename.replace(",", "-");
  ename = ename.replace("&", "");
  ename = ename.replace(" ", "-");
  ename = ename.replace(" ", "-");
  ename = ename.replace(" ", "-");
  ename = ename.replace(" ", "-");
  ename = ename.replace(" ", "-");
  return ename;
};
const buildPartnerButtonText = (partnerName) => {
  if (partnerName === PRODUCT_PARTNERS.ladder) {
    return "SEE MY QUOTE";
  }
  if (partnerName === PRODUCT_PARTNERS.bestow) {
    return "SEE MY QUOTE";
  }
  if (partnerName === PRODUCT_PARTNERS.wagmo) {
    return "SEE MY QUOTE";
  }
  if (partnerName === PRODUCT_PARTNERS.vspdirect) {
    return "SEE MY QUOTE";
  }
  if (partnerName === PRODUCT_PARTNERS.upstart) {
    return "CHECK MY RATE";
  }
  if (partnerName === PRODUCT_PARTNERS.newConstructs) {
    return "GET ACCESS NOW";
  }
  if (partnerName === PRODUCT_PARTNERS.lemonade) {
    return "SEE MY QUOTE";
  }
  if (partnerName === PRODUCT_PARTNERS.bankrate) {
    return "SEE MY OFFERS";
  }
  if (partnerName === PRODUCT_PARTNERS.lemonade) {
    return "SEE MY QUOTE";
  }
  if (partnerName === PRODUCT_PARTNERS.covered) {
    return "GET MY QUOTE";
  }
  if (partnerName === PRODUCT_PARTNERS.youNeedABudget) {
    return "LEARN MORE";
  }
  return "SEE MY QUOTE";
};
const buildLearnLessonPhraseMessage = (phraseDisplay) => {
  if (!phraseDisplay)
    return "";
  if (phraseDisplay.slice(0, 2) === "$!") {
    return phraseDisplay.slice(2, phraseDisplay.length);
  }
  const secondWord = phraseDisplay.split(" ")[1];
  const isSecondWordPlural = secondWord && secondWord.slice(-1).toLowerCase() === "s";
  const isPlural = phraseDisplay.split(" ")[0].slice(-1).toLowerCase() === "s" || isSecondWordPlural;
  return `What ${isPlural ? "are" : "is"} ${phraseDisplay}?`;
};
const buildPartnerExternalURL = (analytics_id, lessonId, partnerName) => {
  if (partnerName === PRODUCT_PARTNERS.ladder) {
    return buildLadderExternalURL(analytics_id, lessonId);
  }
  if (partnerName === PRODUCT_PARTNERS.wagmo) {
    return buildWagmoExternalURL(analytics_id, lessonId);
  }
  if (partnerName === PRODUCT_PARTNERS.vspdirect) {
    return buildVSPDirectExternalURL(analytics_id, lessonId);
  }
  if (partnerName === PRODUCT_PARTNERS.upstart) {
    return buildUpstartExternalURL(analytics_id, lessonId);
  }
  if (partnerName === PRODUCT_PARTNERS.newConstructs) {
    return buildNewConstructsExternalURL(analytics_id, lessonId);
  }
  if (partnerName === PRODUCT_PARTNERS.lemonade) {
    return buildLemonadeExternalURL(analytics_id, lessonId);
  }
  if (partnerName === PRODUCT_PARTNERS.galat) {
    return buildGalatExternalURL(analytics_id, lessonId);
  }
  if (partnerName === PRODUCT_PARTNERS.covered) {
    return buildCoveredExternalURL(analytics_id, lessonId);
  }
  if (partnerName === PRODUCT_PARTNERS.bankrate) {
    return buildBankrateExternalURL();
  }
  if (partnerName === PRODUCT_PARTNERS.bestow) {
    return buildBestowExternalURL();
  }
  if (partnerName === PRODUCT_PARTNERS.youNeedABudget) {
    return "https://www.youneedabudget.com/?irclickid=U-x0y6WutxyNUvuzQbyzlx0ZUkA3NwSG7T4tws0&irgwc=1&utm_medium=affiliate&utm_source=Tornado%20Invest";
  }
  return null;
};
const buildBestowExternalURL = (analytics_id, lessonId) => {
  return `https://bestow.sjv.io/WDmAWe`;
};
const buildLadderExternalURL = (analytics_id, lessonId) => {
  return `https://www.ladderlife.com/tornado?lddr_source=tornado&lddr_name=${"LE" + lessonId.toString()}&external_ID=${analytics_id}`;
};
const buildWagmoExternalURL = (analytics_id, lessonId) => {
  return `https://wagmo.4qxjn9.net/c/3467601/1424950/11106?subId1=${"LE" + lessonId.toString()}&subId2=${analytics_id}`;
};
const buildUpstartExternalURL = (analytics_id, lessonId) => {
  return `https://upstart.9c65.net/c/3467601/1249505/9083?subId1=${"LE" + lessonId.toString()}&subId2=${analytics_id}`;
};
const buildNewConstructsExternalURL = (analytics_id, lessonId) => {
  return `https://www.newconstructs.com/tornado-email-sign-up/?utm_source=Tornado&utm_medium=Tornado&utm_campaign=TornadoPromotion&utm_content=GoldMembershipInterest&subId1=${"LE" + lessonId.toString()}&subId2=${analytics_id}`;
};
const buildVSPDirectExternalURL = (analytics_id, lessonId) => {
  return `http://www.jdoqocy.com/click-100683298-13505679?subId1=${"LE" + lessonId.toString()}&external_ID=${analytics_id}`;
};
const buildLemonadeExternalURL = (analytics_id, lessonId) => {
  return `https://imp.i146998.net/c/3467601/1033653/11498?subId1=${analytics_id}&sharedid=${lessonId}`;
};
const buildGalatExternalURL = (analytics_id, lessonId) => {
  return `https://www.wealthfoundry.co/tornado?subId1=${analytics_id}&external_ID=${lessonId}`;
};
const buildBankrateExternalURL = (analytics_id, lessonId) => {
  return `https://oc.brcclx.com/t/?lid=26685425&cr=19410&last_updated=1465327559`;
};
const buildCoveredExternalURL = (analytics_id, lessonId) => {
  const medium = lessonId === 300009 ? "Howmuch" : "Typesof";
  return `https://getquote.itscovered.com/tornado?utm_source=Auto&utm_medium=${medium}`;
};
const parseQueryString = (query) => {
  if (!query || query.length === 0) {
    return {};
  }
  const keyValues = query.split("?")[1];
  const keyValuesArray = keyValues.split("&");
  const parsedQuery = {};
  keyValuesArray.forEach((string) => {
    const key = string.split("=")[0];
    const value = string.split("=")[1];
    parsedQuery[key] = decodeURI(value);
  });
  return parsedQuery;
};
const createQueryString = (query, options) => {
  const queryArray = [];
  const keys = Object.keys(query);
  if (keys.length === 0 || !keys) {
    return "";
  }
  keys.forEach((key) => {
    const value = query[key];
    queryArray.push(`${key}=${value}`);
  });
  const queryPrefix = options && options.isAppending ? "&" : "?";
  const queryString = queryPrefix + queryArray.join("&");
  return queryString;
};
class ColorTheme {
  constructor(defaultColorThemeName) {
    __publicField(this, "_setCurrentColorThemeName", (newColorThemeName) => {
      return this._currentColorThemeName = newColorThemeName;
    });
    __publicField(this, "_saveToLocalStorage", (newColorThemeName) => {
      window.localStorage.setItem("ct", newColorThemeName);
    });
    __publicField(this, "onColorThemeChange", (newColorThemeName) => {
      if (!Object.values(COLOR_THEME_NAMES).includes(newColorThemeName)) {
        console.error("cannot change to non-defined color theme: " + (newColorThemeName || "nullish"));
        return;
      }
      this._saveToLocalStorage(newColorThemeName);
      this._setCurrentColorThemeName(newColorThemeName);
    });
    __publicField(this, "_buildColorThemes", () => {
      return {
        [COLOR_THEME_NAMES.light]: themes[COLOR_THEME_NAMES.light],
        [COLOR_THEME_NAMES.dark]: themes[COLOR_THEME_NAMES.dark]
      };
    });
    if (!defaultColorThemeName) {
      console.error("defaultColorThemeName param missing in _ColorTheme creation");
    }
    this._currentColorThemeName = defaultColorThemeName;
  }
}
class _TrackingEvent {
  constructor() {
    __publicField(this, "addEventSender", (eventSender) => {
      this.eventSender = eventSender;
    });
    __publicField(this, "addDefaultProperties", (properties) => {
      this.defaultProperties = { ...this.defaultProperties, ...properties };
    });
    __publicField(this, "create", (name, properties = {}) => {
      if (!this.eventSender) {
        console.error("Missing event sender, did addEventSender get removed?");
        return;
      }
      if (this.logEvents) {
        console.log(name, { ...properties, ...this.defaultProperties });
      }
      this.eventSender(name, { ...properties, ...this.defaultProperties });
    });
    this.logEvents = false;
    this.defaultProperties = {};
    this.eventSender = null;
  }
}
const TrackingEvent = new _TrackingEvent();
const fillForX = (x, eventName) => {
  return eventName.replace("${}", x);
};
const EventNames = {};
EventNames.signIn = "Sign In";
EventNames.signInXTimes = "Signed In ${} Times";
EventNames.signInAtLeastXTimes = "Signed In At Least ${} Times";
EventNames.viewLearnLessonScreen = "View Learn Lesson Screen";
EventNames.viewLearnDisclaimerScreen = "View Learn Disclaimer Screen";
EventNames.viewLearnRewardScreen = "View Learn Reward Screen";
EventNames.completeLearnLesson = "Complete Learn Lesson";
EventNames.viewAllLearnLessonsCompletedScreen = "View All Learn Lessons Completed Screen";
EventNames.viewLearnGetStartedBanner = "View Learn Get Started Banner";
EventNames.clickLearnGetStartedBanner = "Click Learn Get Started Banner";
EventNames.pressSponsorCalloutLink = "Press Sponsor Callout Link";
EventNames.pressSeeLearnSponsorRulesAndDisclaimers = "Press Learn Sponsor See Rules and Disclaimers";
EventNames.pressSeeMoreOnLearnTopic = "Press See More On Learn Topic";
EventNames.recurringInvestmentToolFormInputChange = "Recurring Investment Tool Form Input Change";
EventNames.recurringInvestmentToolFormUpdate = "Recurring Investment Tool Form Update";
EventNames.completedDepositLearnDepositLesson = "Completed Deposit Learn Deposit Lesson";
EventNames.viewSharedLearnLessonCompleteScreen = "View Shared Learn Lesson Complete Screen";
EventNames.pressSignupNowSharedLearnLesson = "Press Signup Now Shared Learn Lesson";
EventNames.pressToHomeSharedLearnLesson = "Press To Home Shared Learn Lesson";
EventNames.pressViewPreviousCompletedLesson = "Press View Previous Completed Lesson";
EventNames.pressSharePreviousCompletedLesson = "Press Share Previous Completed Lesson";
EventNames.pressViewLearnHome = "Press View Learn Home";
EventNames.pressProductPartnerCalloutLink = "Press Product Partner Outgoing Link";
EventNames.viewPartnerPromoLessonBanner = "View Partner Promo Lesson Banner";
EventNames.pressViewPartnerPromoLesson = "Press View Partner Promo Lesson";
EventNames.pressCustomMoreInfoLink = "Press Custom Lesson More Info Link";
EventNames.pressExtraContentLink = "Press Learn Extra Content Link";
EventNames.submitForm = "Submit Form";
EventNames.submitFormValidationFailed = "Submit Form Validation Failed";
EventNames.pressReferralCTA = "Press Referral CTA";
EventNames.pressReferralCTA = "Send Referral";
export { COLOR_THEME_KEYS, COLOR_THEME_NAMES, CUSTOMIZE_COLOR_THEME_KEYS, CUSTOM_LEARN_LESSON_COMPONENT_TYPES, ColorTheme, DISPLAY_FORMAT_TYPES, EXTRA_CONTENT_TYPES, EventNames, LEARN_CONTENT_TYPES, LEARN_LESSON_TYPES, LEARN_STATUS_MESSAGES, LEARN_STATUS_TYPES, PRODUCT_PARTNERS, PRODUCT_PLATFORMS, REFERRAL_TYPES, SCREEN_SIZES, TRACKING_ROUTE_NAMES, TrackingEvent, USER_ACTION_CONTEXTS, USER_ACTION_EVENT_NAMES, appRoutes, breakpoints, buildBankrateExternalURL, buildBestowExternalURL, buildCoveredExternalURL, buildGalatExternalURL, buildLadderExternalURL, buildLearnLessonPhraseMessage, buildLemonadeExternalURL, buildLessonParagraphs, buildNewConstructsExternalURL, buildPartnerButtonText, buildPartnerExternalURL, buildUpstartExternalURL, buildVSPDirectExternalURL, buildWagmoExternalURL, colorPalette, convertArrayToDisplayString, convertHexToRGBA, createQueryString, createTimeInstance, doArraysContainSameElementValues, doXTimes, enums, fillForX, filterDuplicates, formatDateValueTo, formatLocalizedDateTime, formatNumber, formatToUntilMessage, formatValueTo, getContrastTheme, getDaysSinceDate, getDaysUntilDate, getDecimalAccuracy, getPrevDay, getRandomIntegerInRange, getStartOfDayTodayEpochValue, getTimeNowEpoch, getWeekNumber, hexToRgb, hexToRgbA, isAfterTimeLength, isDateBefore, isInArray, isMobileBrowser, isMobileDevice, isNull, isSafari, isUndefined, isUndefinedOrNull, isWithinTimeLength, mapXTimes, moment, parseAPIDate, parseDate, parseQueryString, parseToSearchParamsLookup, pickItemWithProbability, pickOne, pluralize, replaceAll, returnLastYear, returnThisDay, returnThisMonth, returnThisYear, rgbToHex, roll, setDateBack, sleep, slugifyLessonName, storeActionTypes, themes, toCapitalCase, toTitleCase, tornadoColorPalette, trackedRouteStore, trackingEvents, trackingEventsValidationSchema, truncate, webappRoutes };
