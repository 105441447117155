import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import rootReducer from '@utils/reducers/index';
// import { rootReducer } from '@lib/nvstr-utils.es';

const configureStore = (customStore) => {
  const store = customStore
    ? createStore(rootReducer, customStore, applyMiddleware(reduxThunk))
    : createStore(rootReducer, applyMiddleware(reduxThunk));
  return store;
};

export default configureStore;
