export const currentUserActionTypes = {
  fetch: 'fetchCurrentUser',
};
export const karmaActionTypes = {
  addKarma: 'addKarma',
  updateKarma: 'updateKarma',
  setReachedMaxDailyKarma: 'setReachedMaxDailyKarma',
};

export const learnActionTypes = {
  addLessonData: 'addLessonData',
  updateCompletedLessons: 'updateCompletedLessons',
  setNextLesson: 'setNextLesson',
};
